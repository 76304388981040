import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { ReactComponent as PhotoIcon } from './images/icon/Photo.svg';
import { ReactComponent as MailIcon } from './images/icon/Pochto.svg';
import { ReactComponent as Track } from './images/icon/Track.svg';
import { ReactComponent as Insurance } from './images/icon/Insurance.svg';
import { ReactComponent as Ransom } from './images/icon/ransom.svg';
import { ReactComponent as Customs } from './images/icon/Customs.svg';
import { ReactComponent as Payment } from './images/icon/Payment.svg';
import { ReactComponent as Service } from './images/icon/service.svg';
import AnimationRevealPage from "./helpers/AnimationRevealPage";
import MainCabinetHeader from "./components/headers/MainCabinetHeader";
import Footer from "./components/footers/MainFooterWithLinks";
import tw from "twin.macro";
import { getAuth } from 'firebase/auth';
import { collection, addDoc, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from './FireBaseConfig';

const GlobalStyle = createGlobalStyle`
    select, option {
        color: #000 !important;
        background-color: #fff !important;
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(45, 45, 45, 0.08);
    overflow: hidden;
    margin: 20px auto;
    width: 90%;
    max-width: 1300px;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    margin-top: 60px;
`;

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    background: #ffffff;
    border-right: 1px solid #F5F5F5;
    @media (min-width: 768px) {
        width: 250px;
    }
`;

const TabButton = styled.button`
    display: flex;
    align-items: center;
    background: ${({ active }) => (active ? '#DDF2E6' : 'transparent')};
    color: #000000;
    border: none;
    padding: 10px 22px;
    font-size: 16px;
    line-height: 18px;
    font-family: 'Gilroy Medium', sans-serif;
    cursor: pointer;
    text-align: left;
    &:hover {
        background-color: #DDF2E6;
    }
    svg {
        margin-right: 20px;
        width: 30px;
        height: 30px;
    }
`;

const IconContainer = styled.span`
    display: flex;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 24px;
    line-height: 29px;
    color: #2D2D2D;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Gilroy Medium', sans-serif;
`;

const StyledParagraph = styled.p`
    padding: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
    color: #2D2D2D;
    font-family: 'Gilroy Medium', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    outline: none;
    cursor: default;
    text-align: left;
`;

const Highlight = styled.span`
    color: #0ABD19;
    font-weight: bold;
    font-size: 25px;
`;

const FormGroup = styled.div`
    ${tw`relative flex flex-col mb-6`}
`;

const Label = styled.label`
    ${tw`mt-1 sm:mt-0 mb-2 font-medium text-secondary-100 leading-loose`}
`;

const CustomSelectWrapper = styled.div`
    position: relative;
    width: 93%;
`;

const CustomSelect = styled.select`
    ${tw`mt-2 w-full px-4 py-3 bg-white text-left rounded-md shadow-sm border border-gray-300 cursor-pointer`}
    color: #000 !important; /* Ensure the text color is black */
    font-family: inherit;
    background-color: #fff !important; /* Ensure the background color is white */
    option {
        color: #000 !important; /* Ensure the text color is black for options */
        background-color: #fff !important; /* Ensure the background color is white for options */
    }
`;

const Input = styled.input`
    ${tw`mt-2 first:mt-0  focus:outline-none font-medium transition duration-300 border-b-2`}
    color: #6c757d;
    border-color: transparent;
    border-bottom-color: #adb5bd;
    font-family: inherit;
    width: calc(100% - 50px);
    &::placeholder {
        color: #adb5bd;
    }
    &:hover {
        border-bottom-color: #0ABD19;
    }
    &:focus {
        border-bottom-color: #0ABD19;
    }
`;

const CustomOptions = styled.ul`
    ${tw`absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md`}
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow py-4`}
`;

const Form = styled.form`
    ${tw`grid grid-cols-1 gap-4 `}
    width: 100%;
    height: 100%;
`;

const StyledContainer = styled.div`
    ${tw`w-full max-w-3xl p-4 bg-white shadow-lg rounded-lg border`}
    border: 2px solid #0ABD19;
    border-radius: 15px;
    padding: 20px 20px;
    margin: 20px auto;
    overflow: hidden;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
`;

const SubmitButton = styled.button`
    ${tw`px-8 py-3 font-medium text-white rounded-full focus:outline-none transition-transform duration-300`}
    background-color: #0ABD19;
    text-decoration: none;
    &:hover {
        background-color: #0ABD50;
        transform: scale(1.05);
    }
`;

const CustomOption = styled.li`
    ${tw`px-4 py-3 text-black cursor-pointer`}
    &:hover {
        background: #f3f4f6;
    }
    ${({ isSelected }) => isSelected && tw`bg-gray-200`}
`;

const ResetButton = styled.button`
    ${tw`px-8 py-3 font-medium text-white rounded-full focus:outline-none transition-transform duration-300`}
    background-color: #0ABD19;
    text-decoration: none;
    &:hover {
        background-color: #0ABD50;
        transform: scale(1.05);
    }
`;

const ContentContainer = styled.div`
    flex: 1;
    padding: 20px;
    transition: opacity 0.5s ease;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`;

const tabs = [
    {
        name: "Фото товаров в посылке",
        icon: PhotoIcon,
        service: "Фото в посылке",
        content: "Предоставить данную услугу. Вам необходимо зайти в личный кабинет, в разделе «услуги склада» выбрать нужную вам посылку, затем нажать на услугу «фото товаров в посылке». В течении 24-48 часов мы выполним данную услугу. В стоимость данной услуги входит 3-5 фотографий всех товаров в посылке, а также ваши товары сверяются на общее соответствие с описанием в личном кабинете. В случае несоответствия товаров мы уведомим вас об этом в личном кабинете. Стоимость услуги – 199 рублей (3-5 фото)"
    },
    {
        name: "Возврат посылки или товара",
        icon: MailIcon,
        service: "Возврат посылки или товара",
        content: "Если по каким-то причинам вы пожелаете вернуть посылку, которая была доставлена на адрес нашего склада обратно в магазин или иному продавцу вашего купленного товара, то мы готовы предоставить вам данную услугу. Вам необходимо выбрать в личном кабинете «услуги склада», затем «возврат» указав адрес возврата. Мы выставим вам счет в личном кабинете, после чего вы сможете оплатить за данную услугу отдельно. В стоимость услуги входит упаковка и доставка до адресата. Стоимость услуги- платная"
    },
    {
        name: "Выкуп товаров",
        icon: Ransom,
        service: "Выкуп товаров",
        content: "Данная услуга дает возможность приобрести товар в других странах напрямую и без каких-либо трудностей с оплатой и не понимая языка. Необходимо заполнить заявку на выкуп товаров в личном кабинете указать все нужные характеристики и ссылку на товар. А все остальное мы сделаем за вас. Воспользовавшись данной услугой порой общая стоимость товаров, купленная нашим сотрудником, будет дешевле, чем если вы сами будете покупать в интернет-магазинах из Казахстана, так как мы покупаем много и часто, и можем использовать различные скидки и купоны. Стоимость услуги – 10% от стоимости товаров, минимальная стоимость -$10"
    },
    {
        name: "Оплата и возврат денежных средств",
        icon: Payment,
        service: "Оплата и возврат денежных средств",
        content: "Предлагаем различные методы оплаты. Оплата за услуги производиться в различных валютах по курсу платежной системы в рублях. Оплачивается комиссия за оплату от общей суммы согласно нижеприведенным тарифам. Мы принимаем к оплате: Карты Казахстана: VISA International, MasterCard Worldwide, UnionPay International, American Express International и Diners Club International. Карты Казахстана: MasterCard Worldwide, UnionPay International"
    },
    {
        name: "Дополнительная услуга",
        icon: Service,
        service: "Дополнительная услуга",
        content: "Дополнительные услуги для вашего удобства"
    },
];

export default function App() {
    const [activeTab, setActiveTab] = useState(0);
    const [activeContent, setActiveContent] = useState(tabs[0].content);
    const [isActive, setIsActive] = useState(true);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        setIsActive(false);
        setTimeout(() => {
            setActiveContent(tabs[activeTab].content);
            setIsActive(true);
        }, 500);
    }, [activeTab]);

    useEffect(() => {
        const fetchPackages = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const q = query(collection(db, 'parcels'), where('userId', '==', user.uid));
                const querySnapshot = await getDocs(q);
                const packagesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPackages(packagesList);
            }
        };
        fetchPackages();
    }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            const form = event.target;
            const data = new FormData(form);
            const packageId = data.get('package');
            const comments = data.get('comments');

            // Debugging logs
            console.log("Form submitted with Package ID:", packageId);
            console.log("Comments:", comments);

            try {
                // Query Firestore to find the document by the id field
                const q = query(collection(db, 'parcels'), where('id', '==', packageId));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    throw new Error("Package not found");
                }

                const packageDoc = querySnapshot.docs[0];
                const packageData = packageDoc.data();

                // Log the entire package data
                console.log("Package Data:", packageData);

                // Check if warehouse field exists and log its value
                if (packageData.hasOwnProperty('warehouse')) {
                    console.log("Warehouse (Country):", packageData.warehouse);
                } else {
                    console.log("Warehouse field is missing");
                }

                await addDoc(collection(db, 'applications'), {
                    userId: user.uid,
                    service: tabs[activeTab].service,
                    packageId: packageDoc.id, // Use the actual document ID
                    comments,
                    country: packageData.warehouse || "Unknown", // Save the country
                    timestamp: new Date() // Save the creation time
                });

                alert('Заявка успешно создана');
                form.reset();
            } catch (error) {
                console.error("Error creating application:", error);
                alert("Ошибка при создании заявки. Пожалуйста, попробуйте снова.");
            }
        }
    };


    return (
        <AnimationRevealPage>
            <GlobalStyle />
            <MainCabinetHeader />
            <Layout>
                <SidebarContainer>
                    {tabs.map((tab, index) => (
                        <TabButton
                            key={index}
                            active={index === activeTab}
                            onClick={() => setActiveTab(index)}
                        >
                            <IconContainer>{<tab.icon active={index === activeTab} />}</IconContainer>
                            {tab.name}
                        </TabButton>
                    ))}
                </SidebarContainer>
                <ContentContainer isActive={isActive}>
                    <StyledParagraph>{tabs[activeTab].content}</StyledParagraph>
                    <Highlight>Заявка на услугу склада - {tabs[activeTab].name}</Highlight>
                    <StyledContainer>
                        <FormContainer>
                            <Form id={`${tabs[activeTab].service}-form`} onSubmit={handleFormSubmit}>
                                <FormGroup>
                                    <Label htmlFor="package">Выберите посылку</Label>
                                    <CustomSelectWrapper>
                                        <CustomSelect id="package" name="package">
                                            <option value="">Выберите посылку</option>
                                            {packages.map((pkg) => (
                                                <option key={pkg.id} value={pkg.id}>{pkg.name}</option>
                                            ))}
                                        </CustomSelect>
                                    </CustomSelectWrapper>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="comments">Комментарий для оператора</Label>
                                    <Input id="comments" name="comments" type="text" />
                                </FormGroup>
                            </Form>
                        </FormContainer>
                    </StyledContainer>
                    <ButtonContainer>
                        <SubmitButton type="submit" form={`${tabs[activeTab].service}-form`}>Отправить заявку</SubmitButton>
                        <ResetButton type="reset" onClick={() => document.getElementById(`${tabs[activeTab].service}-form`).reset()}>Сбросить</ResetButton>
                    </ButtonContainer>
                </ContentContainer>
            </Layout>
            <Footer />
        </AnimationRevealPage>
    );
}
