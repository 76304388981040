import React, { useState,useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import tw from "twin.macro";
import HeaderContainer from "components/headers/WarehouseAccountingHeader"; // Import the header component
import { ReactComponent as SearchIcon } from "images/search-icon.svg";
import Arrow from "images/icon/GreenSmallArrow.svg";
import BoxIcon from "images/icon/cardboard-box_11530648.png"; // Ensure you have the BoxIcon PNG file
import Printer from "images/icon/Printerr.svg";
import { ReactComponent as PlusIcon } from "images/icon/PlusIcon.svg"; // Ensure you have the PlusIcon SVG file
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../FireBaseConfig';
const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'SFUIText';
        font-weight: 300;
        font-style: normal;
    }

    body, html {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'SFUIText', sans-serif;
    }
`;

const Container = styled.div`
    ${tw`min-h-screen`}
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div`
    ${tw`flex flex-col lg:flex-row`}
    flex-grow: 1;
`;

const Sidebar = styled.div`
    ${tw`bg-white shadow-lg`}
    width: 250px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    height: 100vh; /* Full height of the page */

    @media (max-width: 768px) {
        width: 200px;
    }
`;

const SidebarItem = styled.div`
    ${tw`flex flex-col mb-4 `}
    text-decoration: none;
`;

const SidebarItemTitle = styled.div`
    ${tw`flex justify-between items-center cursor-pointer p-2 text-gray-800 font-semibold`}
    &:hover {
        background-color: #f1f1f1;
    }
`;

const SidebarItemIconText = styled.div`
    ${tw`flex items-center`}
`;

const SidebarSubItem = styled.a`
    ${tw`flex items-center pl-8 text-gray-600 cursor-pointer my-2`}
    text-decoration: none;
    &:hover {
        color: #0ABD19;
    }
`;

const Icon = styled.img`
    ${tw`mr-1`} /* Adjusted the margin to ensure the text is closer to the icon */
    width: 25px;  // Increased the size of the icon
    height: 25px; // Increased the size of the icon
`;

const Content = styled.div`
    ${tw`flex-grow p-8`}
    display: flex;
    flex-direction: column;
`;

const Heading = styled.h1`
    ${tw`text-green-500 font-semibold text-xl`}
`;

const Divider = styled.div`
    ${tw`bg-gray-300`}
    height: 1px;
    width: 100%;
    margin: 20px 0;
`;

const Form = styled.div`
    ${tw`flex items-center space-x-6`} /* Increased the spacing between inputs */
    gap: 1rem;
`;

const Label = styled.label`
    ${tw`text-sm font-semibold mb-4`}
    color: #d3d3d3;
`;

const InputContainer = styled.div`
    ${tw`flex flex-col relative items-center`}
    flex: 1;
`;

const Input = styled.input`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600 mt-4 pl-10`} /* Adjusted padding for icon */
    border-color: #D3D3D3;
    width: 96%;
    &:hover, &:focus {
        border-color: #0ABD19;
    }
`;

const SearchIconContainer = styled.div`
    ${tw`absolute inset-y-0 left-0 flex items-center pl-3 mt-4 `}
    pointer-events: none;
`;

const SidebarToggleIcon = styled.img.attrs({ src: Arrow })`
    ${tw`ml-2 transition-transform duration-300`}
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const SidebarMenu = ({ title, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <SidebarItem>
            <SidebarItemTitle onClick={() => setIsOpen(!isOpen)}>
                <SidebarItemIconText>
                    <Icon src={BoxIcon} />
                    {title}
                </SidebarItemIconText>
                <SidebarToggleIcon isOpen={isOpen} />
            </SidebarItemTitle>
            {isOpen && items.map((item, index) => (
                <SidebarSubItem href={item.href} key={index}>
                    <Icon src={BoxIcon} />
                    {item.name}
                </SidebarSubItem>
            ))}
        </SidebarItem>
    );
};

const InfoText = styled.p`
    ${tw`text-gray-600 text-base items-start ml-2 font-bold `}
    color: #999999; /* Light grey color */
`;

const LabelsContainer = styled.div`
    ${tw`grid grid-cols-12 gap-0 p-4`}
`;

const DataGrid = styled.div`
    ${tw`grid grid-cols-12 gap-0 bg-white p-4 rounded-md`}
    margin-top: 0px;
`;

const DataCell = styled.div`
    ${tw`p-2`}
    border: 1px solid #d3d3d3;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 29px;
    height: 40px; /* Reduced height */
`;

const CellLabel = styled.span`
    ${tw`font-semibold text-sm text-gray-800`}
`;

const PrintIcon = styled.img`
    ${tw`w-5 h-5 cursor-pointer`}
`;

const ExpectedParcelsPage = () => {
    const [parcels, setParcels] = useState([]);

    useEffect(() => {
        const fetchParcels = async () => {
            const querySnapshot = await getDocs(collection(db, "parcels"));
            const parcelsData = querySnapshot.docs.map(doc => doc.data());
            setParcels(parcelsData);
        };

        fetchParcels();
    }, []);

    return (
        <Container>
            <GlobalStyle />
            <HeaderContainer />
            <MainContent>
                <Sidebar>
                    {/* Sidebar items */}
                </Sidebar>
                <Content>
                    <Heading>Входящие посылки</Heading>
                    <Divider />
                    <Form>
                        <InputContainer>
                            <SearchIconContainer>
                                <SearchIcon />
                            </SearchIconContainer>
                            <Input placeholder="Search" />
                        </InputContainer>
                    </Form>
                    <InfoText>Общее количество посылок: {parcels.length}</InfoText>
                    <LabelsContainer>
                        <CellLabel style={{ gridColumn: 'span 1' }}>Shelf no</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 1' }}>№ заявки</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 1' }}>Дата приема</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 3' }}>ID клиента</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 4' }}>Tracking ID</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 2' }}>Print</CellLabel>
                    </LabelsContainer>
                    <DataGrid>
                        {parcels.map((parcel, index) => (
                            <React.Fragment key={index}>
                                <DataCell style={{ gridColumn: 'span 1' }}>
                                    {parcel.shelf || "Еще не обработана"}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 1' }}>
                                    {parcel.id}
                                    <br/>
                                    {parcel.status}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 1' }}>
                                    {parcel.dateReceived || "Еще нету"} {/* Display dateReceived */}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 3' }}>
                                    {parcel.id}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 4' }}>
                                    {parcel.trackingNumber || "N/A"}
                                </DataCell>
                                <DataCell style={{ gridColumn: 'span 2' }}>
                                    <PrintIcon src={Printer} alt="Print" />
                                </DataCell>
                            </React.Fragment>
                        ))}
                    </DataGrid>
                </Content>
            </MainContent>
        </Container>
    );
};

export default ExpectedParcelsPage;