import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import plusIcon from "../../images/plus.png"; // Updated import to follow naming conventions

const CardContainer = styled.div`
    ${tw`w-full max-w-2xl mx-auto my-8`}
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #00bc00;
    min-height: 300px;
    border-radius: 20px;
    @media (max-width: 768px) {
        margin-left: 8px;
        width: calc(100% - 20px); /* Adding side margins on mobile */
    }
`;

const CardHeaderContainer = styled.div`
    ${tw`flex`}
    @media (max-width: 768px) {
    ${tw`flex-col`}
}
`;

const CardHeaderLink = styled.a`
    ${tw`text-green-500 text-lg font-medium relative flex-shrink-0 h-auto py-10 no-underline`}
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    padding-right: 20px;
    border-left: 2px solid #00bc00;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 37%;
    @media (max-width: 768px) {
        writing-mode: horizontal-tb;
        transform: rotate(0);
        padding: 10px;
        border-left: none;
        border-bottom: 2px solid #00bc00;
    }
`;

const CardContentContainer = styled.div`
    ${tw`flex flex-col p-8 w-full`}
    width: 70%;
    @media (max-width: 768px) {
        ${tw`w-full`}
        padding: 2px; /* Уменьшение padding для мобильных устройств */
    }
`;

const CardContent = styled.div`
    ${tw`text-gray-700 text-base mb-6`}
    @media (max-width: 768px) {
    ${tw`text-sm`} /* Уменьшение размера текста для мобильных устройств */
}
`;

const CardStats = styled.div`
    ${tw`flex flex-col text-gray-500 text-base mb-6`}
`;

const Stat = styled.div`
    ${tw`mb-4`}
`;

const StatLabel = styled.div`
    ${tw`flex items-center justify-between`}
`;

const StatValue = styled.div`
    ${tw`ml-1 text-right mr-6`}
`;

const CardActions = styled.div`
    ${tw`mt-auto flex justify-between items-center`}
    @media (max-width: 768px) {
    ${tw`flex-wrap w-full justify-between`}
}
`;

const Navlink = styled.a`
    text-decoration: none;
    ${tw`flex items-center justify-center text-white font-bold`}
`;

const Button = styled.button`
    ${tw`flex items-center justify-center py-4 bg-green-1002 text-white rounded-lg font-bold transition duration-300 ease-in-out hover:bg-green-600 focus:outline-none`}
    svg {
        ${tw`mr-2`}
    }
    border: none;
    width: 48%; // Ensure both buttons have the same width
    min-width: 200px; // Adjust the min-width to make buttons the same size
    @media (max-width: 768px) {
        ${tw`w-full`} // Ensure both buttons take up equal space on mobile
        min-width: unset; // Remove min-width for better fit on small screens
    }
`;

const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 8px; // Add some space between the icon and text
`;

const IncomingPackagesCard = () => {
    return (
        <CardContainer>
            <CardHeaderContainer>
                <CardHeaderLink href="/IncomingParcels">Входящие посылки</CardHeaderLink> {/* Change the href to your desired page */}
                <CardContentContainer>
                    <CardContent>
                        Ожидаемые посылки и посылки прибывшие на склад:
                    </CardContent>
                    <CardStats>
                        <Stat>
                            <StatLabel>
                                <div>Ожидаемых:</div>
                                <StatValue>4 шт.</StatValue>
                            </StatLabel>
                        </Stat>
                        <Stat>
                            <StatLabel>
                                <div>На складе:</div>
                                <StatValue>2 шт.</StatValue>
                            </StatLabel>
                        </Stat>
                    </CardStats>
                    <CardActions>
                        <Navlink href="/ExpectedLink">
                            <Button>
                                <Icon src={plusIcon} alt="Plus Icon" />
                                Добавить посылку
                            </Button>
                        </Navlink>
                        <Navlink href="/IncomingParcels">
                            <Button>
                                Входящие посылки
                            </Button>
                        </Navlink>
                    </CardActions>
                </CardContentContainer>
            </CardHeaderContainer>
        </CardContainer>
    );
};

export default IncomingPackagesCard;
