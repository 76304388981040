import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../FireBaseConfig';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import AnimationRevealPage from '../../helpers/AnimationRevealPage';
import IllustrationImage from '../../images/Credit-card.jpg'; // Ensure this path is correct

const Container = styled.div`
    background: #f7f8fc;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 20px;
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    height: 80vh; /* Ensures the content takes up most of the viewport height */
    flex-wrap: wrap; /* Ensure content wraps on smaller screens */
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    margin-right: 20px;
`;

const FormGroup = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    font-weight: 500;
`;

const Input = styled.input`
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    &:focus {
        border-color: #27ae60;
        outline: none;
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const SmallInput = styled(Input)`
    flex: 1;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
`;

const Button = styled.button`
    background: #27ae60;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    transition: background 0.3s ease;
    &:hover {
        background: #1e8449;
    }
`;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const IllustrationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 20px;
`;

const Illustration = styled.img`
    max-width: 100%;
    height: auto;
`;

const PaymentForm = () => {
    const location = useLocation();
    const [amount, setAmount] = useState(location.state?.amount || '');
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [focused, setFocused] = useState('');
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUser(currentUser);
            fetchUserBalance(currentUser.uid);
        }
    }, []);

    const fetchUserBalance = async (userId) => {
        try {
            const balanceDoc = await getDoc(doc(db, 'balances', userId));
            if (balanceDoc.exists()) {
                setBalance(balanceDoc.data().balance);
            } else {
                console.log('No balance document found for user.');
                await setDoc(doc(db, 'balances', userId), { balance: 0 });
                setBalance(0);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const amountInCents = parseInt(amount, 10) * 100;
            const orderId = `order${Date.now()}`;

            const paymentData = {
                Key: 'MerchantEParcelinPay', // Ensure this key is correct
                SessionType: 'Pay',
                OrderId: orderId,
                Amount: amountInCents,
                PayInfo: `PAN=${cardNumber};EMonth=${expiry.split('/')[0]};EYear=${expiry.split('/')[1]};CardHolder=${cardHolder};SecureCode=${cvv};OrderId=${orderId};Amount=${amountInCents}`,
                CustomFields: `IP=127.0.0.1;Product=TestProduct`,
            };

            const queryString = Object.keys(paymentData)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(paymentData[key])}`)
                .join('&');

            const proxyUrl = 'https://api.allorigins.win/get?url=';
            const apiUrl = `${proxyUrl}${encodeURIComponent(`https://sandbox3.payture.com/api/Pay?${queryString}`)}`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            const data = await response.json();
            const decodedData = decodeURIComponent(data.contents);
            console.log('Payment API response:', decodedData);

            if (response.ok) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(decodedData, 'text/xml');
                const payElement = xmlDoc.getElementsByTagName('Pay')[0];
                const success = payElement.getAttribute('Success');

                if (success === 'True') {
                    await addDoc(collection(db, 'payments'), {
                        userId: user.uid,
                        amount: amount,
                        date: new Date(),
                    });

                    const newBalance = balance + parseInt(amount, 10);
                    await setDoc(doc(db, 'balances', user.uid), {
                        balance: newBalance,
                    });

                    alert('Оплата успешно проведена');
                } else {
                    const errCode = payElement.getAttribute('ErrCode');
                    console.error(`Payment API error: ${decodedData}`);
                    alert(`Ошибка при проведении оплаты: ${errCode}`);
                }
            } else {
                console.error('Unexpected response from payment API:', decodedData);
                alert('Ошибка при проведении оплаты');
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Ошибка при проведении оплаты');
        }
    };

    return (
        <AnimationRevealPage>
            <Container>
                <Content>
                    <FormContainer>
                        <form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label htmlFor="cardNumber">Номер карты</Label>
                                <Input
                                    type="tel"
                                    name="number"
                                    id="cardNumber"
                                    placeholder="1234 5678 9012 3456"
                                    value={cardNumber}
                                    onChange={(e) => setCardNumber(e.target.value)}
                                    onFocus={(e) => setFocused(e.target.name)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="cardHolder">Имя владельца карты</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="cardHolder"
                                    placeholder="Имя владельца"
                                    value={cardHolder}
                                    onChange={(e) => setCardHolder(e.target.value)}
                                    onFocus={(e) => setFocused(e.target.name)}
                                />
                            </FormGroup>
                            <Row>
                                <SmallInput
                                    type="tel"
                                    name="expiry"
                                    id="expiry"
                                    placeholder="MM/YY"
                                    value={expiry}
                                    onChange={(e) => setExpiry(e.target.value)}
                                    onFocus={(e) => setFocused(e.target.name)}
                                />
                                <SmallInput
                                    type="tel"
                                    name="cvc"
                                    id="cvv"
                                    placeholder="CVV"
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                    onFocus={(e) => setFocused(e.target.name)}
                                />
                            </Row>
                            <FormGroup>
                                <Label htmlFor="amount">Сумма</Label>
                                <Input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    placeholder="Сумма"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    onFocus={(e) => setFocused(e.target.name)}
                                />
                            </FormGroup>
                            <Button type="submit">Оплатить</Button>
                        </form>
                    </FormContainer>
                    <CardContainer>
                        <Cards
                            number={cardNumber}
                            name={cardHolder}
                            expiry={expiry.replace('/', '')}
                            cvc={cvv}
                            focused={focused}
                        />
                    </CardContainer>
                    <IllustrationContainer>
                        <Illustration src={IllustrationImage} alt="Credit Card Illustration" />
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>
    );
};

export default PaymentForm;
