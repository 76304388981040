import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import Header from "components/headers/MainCabinetHeader";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Footer from "components/footers/MainFooterWithLinks";
import plusIcon from "../images/plus.png";
import { getFirestore, collection, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import { auth } from "../FireBaseConfig";
import { MdDelete } from "react-icons/md";
import TopUpCard from "../components/cards/TopUpCard";
import SingleProfileCard from "../components/cards/SingleProfileCard";

const Container = styled.div`
    ${tw`relative w-full min-h-screen`}
    padding: 0;
    margin: 0;
    box-sizing: border-box;
`;

const InfoContainer = styled.div`
    ${tw`flex w-full items-center justify-between gap-2 mt-4`}
    ${tw`p-4 sm:px-4 md:px-8 lg:px-12 xl:px-16 sm:px-0 md:px-0 lg:px-0 xl:px-0`}
`;

const InfoBox = styled.div`
    ${tw`flex items-center justify-between bg-white shadow-md rounded-lg p-2`}
    border: 2px solid #e2e8f0;
    width: 250px; // Adjust width as needed
`;

const InfoText = styled.span`
    ${tw`text-gray-600`}
`;

const IconButton = styled.a`
    ${tw`ml-4 bg-green-500 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;
    margin-left: auto;
    margin-top: 0;
    width: 20px;
    height: 20px;

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;

const BaseButtonStyles = css`
    ${tw`px-6 py-2 font-semibold rounded-lg shadow-md bg-gray-300 text-lg text-gray-600`}
    ${tw`h-10 w-full sm:w-auto`}
    ${tw`m-2`}
    border: 2px solid #0ABD19;

    &:hover {
        ${tw`text-black bg-green-200`}
    }

    &:focus {
        ${tw`text-black bg-green-200`}
    }
`;

const TwoColumn = styled.div`
    ${tw`flex flex-col lg:flex-row lg:items-start max-w-screen-xl mx-auto py-20 md:py-24`}
    padding-bottom: 0;
`;

const LeftColumn = styled.div`
    ${tw`relative w-full text-left mx-auto`}
`;

const RightColumn = styled.div`
    ${tw`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`}
`;

const Heading = styled.h1`
    ${tw`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-4xl text-gray-900 leading-tight`}
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const PrimaryButton = styled.button`
    ${BaseButtonStyles}
    ${({ selected }) =>
            selected &&
            css`
                ${tw`bg-green-200 border-green-600 text-black`}
            `}
`;

const ButtonContainer = styled.div`
    ${tw`flex flex-wrap items-center justify-start gap-4`}
    ${tw`p-0`}
    ${tw`sm:px-0 md:px-0 lg:px-0 xl:px-0`}
    ${tw`mt-8`}
`;

const ButtonAdd = styled.button`
    ${tw`ml-4 w-auto bg-gray-300 text-gray-600 font-bold py-3 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    border: none;
    width: 200px;
    height: 40px;
    padding: 0 1rem;

    &:hover, &:focus {
        transform: scale(1.1);
        background-color: #0ABD19;
        color: white;
    }
    &:focus::placeholder {
        color: transparent;
    }
`;

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
    ${tw`relative flex items-center w-full gap-8`}
`;

const Description = styled.p`
    ${tw`my-5 lg:my-8 text-base xl:text-lg`}
`;

const BottomButtonsContainer = styled.div`
    ${tw`flex justify-start gap-4 `}
`;

const BottomButton = styled.button`
    ${tw`w-auto bg-green-500 text-white font-bold py-3 px-6 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;
    width: 300px;
    height: 60px;

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;

const NavigationBanner = styled.div`
    ${tw`w-full py-8 mb-16 mt-10`}
    background: #EBFAE5;
    text-align: center;
    font-size: 1rem;
    color: #2D2D2D;
`;

const NavigationLink = styled.a`
    font-family: 'SFUIText', sans-serif;
    ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1`}
    ${tw`hover:border-primary-500 hover:text-primary-500 focus:text-primary-500`}
    text-decoration: none;
    color: #2D2D2D;

    &:hover, &:focus {
        color: #0ABD19;
        text-decoration: none;
    }
`;

const CustomLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
`;

const CardsContainer = styled.div`
    ${tw`grid grid-cols-1 md:grid-cols-2 gap-8 max-w-screen-xl mx-auto mb-20`}
    margin-top: 46px;
`;

const HighlightedText = styled.span`
    ${tw`font-bold`}
    color: #0ABD19;
`;
const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 15px;
`;

const BackButton = styled(ButtonAdd)`
    ${tw`w-auto bg-gray-300 text-gray-600 font-bold py-3 px-4 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    margin-left: 0;
`;

const NavLink = styled.a`
    ${tw`text-lg mx-6 my-0 font-medium tracking-wide transition duration-300 pb-1`}
    text-decoration: none;
    color: inherit;

    &:hover, &:focus {
        color: #0ABD19;
        text-decoration: none;
    }
`;

const Card = styled.div`
    ${tw`p-4 rounded-lg flex flex-col`}
    border-radius: 20px;
    border: 1px solid #0ABD19;
    width: 250px; // Adjusted width to be smaller
    height: 150px; // Adjusted height to be smaller
    box-sizing: border-box;
`;

const CardTitle = styled.h2`
    ${tw`text-sm font-medium text-green-1002`} // Adjusted text size to be smaller
`;

const CardText = styled.p`
    ${tw`text-xs font-medium text-black`} // Adjusted text size to be smaller
`;

const EditLink = styled.a`
    ${tw`mt-2 text-xs text-gray-700`}
    cursor: pointer;
`;

const DeleteIcon = styled.button`
    ${tw`ml-auto `}
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -20px;
    &:hover {
        color: red;
    }
`;

const PersonalCabinet = ({ roundedHeaderButton }) => {
    const [recipients, setRecipients] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchRecipients = async () => {
            const db = getFirestore();
            const user = auth.currentUser;
            if (user) {
                const q = query(collection(db, "recipients"), where("userId", "==", user.uid));
                const querySnapshot = await getDocs(q);
                const fetchedRecipients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRecipients(fetchedRecipients);
            }
            setLoading(false);
        };

        fetchRecipients();
    }, []);

    const [showFirstImage, setShowFirstImage] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const handleEdit = (recipient) => {
        navigate('/RecipientsForm', { state: { recipient } });
    };

    const handleDelete = async (recipientId) => {
        try {
            const db = getFirestore();
            await deleteDoc(doc(db, "recipients", recipientId));
            setRecipients(recipients.filter(recipient => recipient.id !== recipientId));
        } catch (error) {
            console.error("Error deleting recipient: ", error);
        }
    };

    return (
        <>
            <AnimationRevealPage>
                <Header roundedHeaderButton={roundedHeaderButton} />
                <Container>
                    <TwoColumn>
                        <LeftColumn>
                            <Heading>
                                Получатели
                                <CustomLink to="/PersonalArea">
                                    <BackButton>
                                        Назад в личный кабинет
                                    </BackButton>
                                </CustomLink>
                            </Heading>
                            {loading ? (
                                <Description>Загрузка...</Description>
                            ) : recipients.length === 0 ? (
                                <Description>
                                    У вас не добавлено ни одного получателя посылок.<br />
                                    Нажмите кнопку "Добавить получателя" и следуйте инструкции, заполните все поля и выберите получателя в данном шаге.
                                </Description>
                            ) : (
                                <CardsContainer>
                                    {recipients.map(recipient => (
                                        <Card key={recipient.id}>
                                            <CardTitle>{recipient.name} {recipient.surname}</CardTitle>
                                            <CardText>{recipient.phone}</CardText>
                                            <EditLink onClick={() => handleEdit(recipient)}>Редактировать данные</EditLink>
                                            <DeleteIcon onClick={() => handleDelete(recipient.id)}> <MdDelete/>️</DeleteIcon>
                                        </Card>
                                    ))}
                                </CardsContainer>
                            )}
                            <NavLink href="/RecipientsForm">
                                <BottomButtonsContainer>
                                    <BottomButton>
                                        <Icon src={plusIcon} alt="Plus Icon" />
                                        Добавить получателя
                                    </BottomButton>
                                </BottomButtonsContainer>
                            </NavLink>
                        </LeftColumn>
                    </TwoColumn>
                    <NavigationBanner>
                        <NavigationLink href="/custom-rules">Таможенные правила</NavigationLink>
                        <NavigationLink href="/delivery-calculator">Калькулятор доставки</NavigationLink>
                        <NavigationLink href="/prohibited-goods">Запрещенные товары к пересылке</NavigationLink>
                        <NavigationLink href="/ask-question">Задать вопрос</NavigationLink>
                        <NavigationLink href="/add-package">Добавить посылку</NavigationLink>
                    </NavigationBanner>
                    <CardsContainer>
                        <TopUpCard />
                        <SingleProfileCard />
                    </CardsContainer>
                </Container>
                <Footer />
            </AnimationRevealPage>
        </>
    );
};

export default PersonalCabinet;
