import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col items-center justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled(Column)(props => [
    tw`md:w-6/12 flex-shrink-0 relative`,
    css`
        margin-left: 0%;
        max-width: 95%;
    `
]);
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
    css`
        width: 100%;
        height: auto;
        @media (max-width: 768px) {
            width: auto;
            max-width: 100%;
        }
    `
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const StepsContainer = tw.div`mt-12 flex flex-col items-center`;
const StepsRow = tw.div`flex flex-wrap justify-center w-full`;
const Step = styled.div`
    ${tw`w-full sm:w-1/2 lg:w-1/4 flex flex-col items-center text-center p-4`}
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 8px;
    padding: 16px;
`;

const StepNumber = styled.div`
    ${tw`font-semibold text-4xl leading-none text-[#0ABD19]`}
    min-width: 80px;
`;

const StepText = styled.div`
    ${tw`flex flex-col justify-center`}
`;
const StepHeading = tw.h6`text-xl font-semibold leading-none mt-1`;
const StepDescription = tw.p`mt-2 text-lg font-medium`;

export default ({
                    subheading = "Our Expertise",
                    heading = (
                        <>
                            Как это <span tw="text-primary-500">работает?</span>
                        </>
                    ),
                    imageSrc = TeamIllustrationSrc,
                    imageRounded = true,
                    imageBorder = false,
                    imageShadow = false,
                    imageDecoratorBlob = false,
                    textOnLeft = true,
                    steps = null,
                    decoratorBlobCss = null,
                }) => {

    const defaultSteps = [
        {
            heading: "Регистрация",
            description: "Зарегистрируйтесь на нашем сайте и получите адрес для доставки ваших покупок за рубежом"
        },
        {
            heading: "Покупка",
            description: "Заказывайте товары в зарубежных интернет-магазинах и отправляйте их к нам на склад"
        },
        {
            heading: "Оформление",
            description: "Оформляйте доставку в Казахстан. Отправляйте товары из заграницы друзьям, клиентам или себе"
        },
        {
            heading: "Получение",
            description: "Получайте посылки прямо до дома"
        }
    ];

    if (!steps) steps = defaultSteps;

    return (
        <Container>
            <TwoColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Heading>{heading}</Heading>
                        <StepsContainer>
                            <StepsRow>
                                {steps.slice(0, 2).map((step, index) => (
                                    <Step key={index}>
                                        <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                        <StepText>
                                            <StepHeading>{step.heading}</StepHeading>
                                            <StepDescription>{step.description}</StepDescription>
                                        </StepText>
                                    </Step>
                                ))}
                            </StepsRow>
                            <StepsRow>
                                {steps.slice(2).map((step, index) => (
                                    <Step key={index + 2}>
                                        <StepNumber>{(index + 3).toString().padStart(2, '0')}</StepNumber>
                                        <StepText>
                                            <StepHeading>{step.heading}</StepHeading>
                                            <StepDescription>{step.description}</StepDescription>
                                        </StepText>
                                    </Step>
                                ))}
                            </StepsRow>
                        </StepsContainer>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
