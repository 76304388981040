import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const CardContainer = styled.div`
    ${tw` w-full max-w-2xl mx-auto my-8`}
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #45A1E3;
    min-height: 300px;
    border-radius: 20px;
    @media (max-width: 768px) {
        margin-left: 8px;
        width: calc(100% - 20px); /* Adding side margins on mobile */
    }
`;

const CardHeaderContainer = styled.div`
    ${tw`flex`}
    @media (max-width: 768px) {
    ${tw`flex-col`}
}
`;

const CardHeaderLink = styled.a`
    ${tw`text-blue-600 text-lg font-bold relative flex-shrink-0 h-auto py-10 no-underline`}
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    padding-right: 20px;
    border-left: 2px solid #45A1E3;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 37%;
    @media (max-width: 768px) {
        writing-mode: horizontal-tb;
        transform: rotate(0);
        padding: 10px;
        border-left: none;
        border-bottom: 2px solid #45A1E3;
    }
`;

const CardContentContainer = styled.div`
    ${tw`flex flex-col p-8 w-full`}
    width: 70%;
    @media (max-width: 768px) {
        ${tw`w-full`}
        padding: 4px; /* Уменьшение padding для мобильных устройств */
    }
`;

const CardContent = styled.div`
    ${tw`text-gray-700 text-base mb-6`}
    @media (max-width: 768px) {
    ${tw`text-sm`} /* Уменьшение размера текста для мобильных устройств */
}
`;

const CardStats = styled.div`
    ${tw`flex flex-col text-gray-500 text-base`}
`;

const Stat = styled.div`
    ${tw`mb-2 flex justify-between`}
`;

const StatLabel = styled.div`
    ${tw`flex items-center`}
`;

const StatValue = styled.div`
    ${tw`ml-1 flex-grow text-right mt-1 mr-16`}
`;

const CardActions = styled.div`
    ${tw`mt-auto flex justify-between items-center`}
    @media (max-width: 768px) {
    ${tw`flex-col w-full`}
}
`;

const Navlink = styled.a`
    text-decoration: none;
    ${tw`flex items-center justify-center text-white font-bold`}
`;

const Button = styled.button`
    ${tw`flex items-center justify-center py-4 bg-blue-500 text-white rounded-lg font-bold transition duration-300 ease-in-out hover:bg-blue-600 focus:outline-none`}
    svg {
        ${tw`mr-2`}
    }
    border: none;
    width: 48%; // Ensure both buttons have the same width
    min-width: 200px; // Adjust the min-width to make buttons the same size
    @media (max-width: 768px) {
        ${tw`w-full mb-4 mr-8`}
    }
`;

const OutgoingPackagesCard = () => {
    return (
        <CardContainer>
            <CardHeaderContainer>
                <CardHeaderLink href="/OutgoingParcels">Исходящие посылки</CardHeaderLink> {/* Change the href to your desired page */}
                <CardContentContainer>
                    <CardContent>
                        Отправленные и доставленные посылки:
                    </CardContent>
                    <CardStats>
                        <Stat>
                            <StatLabel>
                                <div>Обработанные:</div>
                            </StatLabel>
                            <StatValue>0 шт.</StatValue>
                        </Stat>
                        <Stat>
                            <StatLabel>
                                <div>Отправление:</div>
                            </StatLabel>
                            <StatValue>2 шт.</StatValue>
                        </Stat>
                        <Stat>
                            <StatLabel>
                                <div>Отправлено:</div>
                            </StatLabel>
                            <StatValue>6 шт.</StatValue>
                        </Stat>
                        <Stat>
                            <StatLabel>
                                <div>Прибыло в Казахстан на таможне:</div>
                            </StatLabel>
                            <StatValue>0 шт.</StatValue>
                        </Stat>
                        <Stat>
                            <StatLabel>
                                <div>Передача в службу доставки:</div>
                            </StatLabel>
                            <StatValue>0 шт.</StatValue>
                        </Stat>
                        <Stat>
                            <StatLabel>
                                <div>Доставлено:</div>
                            </StatLabel>
                            <StatValue>0 шт.</StatValue>
                        </Stat>
                    </CardStats>
                    <CardActions>
                        <Navlink href="/OutgoingParcels">
                        <Button>

                                Исходящие посылки

                        </Button>
                    </Navlink>
                        <Navlink href="/DeliveredParcels">
                        <Button>

                                Доставленные посылки

                        </Button>
                        </Navlink>
                    </CardActions>
                </CardContentContainer>
            </CardHeaderContainer>
        </CardContainer>
    );
};

export default OutgoingPackagesCard;
