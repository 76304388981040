import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MainFooterWithLinks";
import { SectionHeading } from "components/misc/Headings";

const HighlightedText = styled.span`
    ${tw`font-semibold text-black`}
    color: #2D2D2D; // Make it slightly bolder and in the color specified
`;


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  a {
    text-decoration: none;
  }
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "СОГЛАШЕНИЕ о возмездном оказании услуг на условиях публичной оферты" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Text>
                        <p>Товарищество с ограниченной ответственностью « EPARCEL-QAZAQSTAN», действующее от собственного имени, и именуемое в дальнейшем «Исполнитель», с одной стороны и любое физическое дееспособное лицо, регистрируясь на сайте <a href="http://www.eparcel.ru/">eparcel.ru</a>,
                            именуемое в дальнейшем «Клиент», с другой стороны, далее совместно именуемые «Стороны», а каждая по отдельности «Сторона», заключили настоящее Соглашение согласно которому, настоящее Соглашение является публичным предложением неограниченному кругу физических лиц воспользоваться услугами, оказываемыми в соответствии с положениями Гражданского кодекса РК, на условиях, изложенных ниже:</p>

                        <h1>1. Общие положения</h1>
                        <p>
                            1.1. Настоящее Соглашение направлено на установление прав и обязанностей Сторон, а также
                            порядка их взаимодействия при оказании комплекса посреднических услуг по организации
                            пересылки (обработки, перевозки и доставки адресату) экспресс-грузов, от места отправки
                            экспресс-груза, выбранного Клиентом, до места, указываемого Клиентом при заказе услуги в
                            качестве места доставки. <br/>
                            1.2. За Исполнителем сохраняется право вносить в текст настоящего Соглашения любые
                            изменения. Условия настоящего Соглашения могут быть изменены Исполнителем в одностороннем
                            безакцептном порядке. Исполнитель публикует измененный текст Соглашения в новой редакции за
                            5 (Пять) календарных дней до даты вступления изменений в силу на сайте <a
                            href="http://www.eparcel.ru/">www.eparcel.kz.</a>
                            Стороны подтверждают, что нахождение Соглашения на сайте достаточно, чтобы считать, что
                            изменения исходят от Исполнителя. Клиент обязуется самостоятельно отслеживать изменения
                            условий настоящего Соглашения по адресу <a href="http://www.eparcel.ru/">www.eparcel.kz.</a>
                            Настоящее Соглашение заключается
                            путем безусловного присоединения Клиента к нему, и Клиент не имеет права на его изменение
                            и/или дополнение.<br/>
                            1.3. Клиент, соглашаясь с условиями взаимодействия Сторон, изложенными в настоящем
                            Соглашении, принимает на себя обязанности по их безоговорочному соблюдению и несет полную
                            самостоятельную материальную ответственность за такое соблюдение.
                            Обязательным условием принятия Соглашения является регистрация Клиента посредством
                            разработанной Исполнителем формы и достоверное предоставление всей необходимой информации,
                            запрашиваемой Исполнителем для целей оказания услуг по настоящему Соглашению. Клиент
                            подтверждает свое согласие с текстом настоящего Соглашения при регистрации на сайте
                            Исполнителя.
                            Соглашаясь с условиями настоящего Соглашения и проставляя соответствующую web-метку в
                            регистрационной форме на сайте, Клиент подтверждает, что до момента его заключения он
                            ознакомился с настоящим Соглашением в полном объеме и дает свое согласие на все его условия,
                            без внесения изменений и/или дополнений.
                            Весь документооборот осуществляется через Личный кабинет Клиента на сайте по адресу
                            <a href="http://www.eparcel.ru/">www.eparcel.kz.</a> Исполнитель вправе публиковать на
                            сайте <a href="http://www.eparcel.ru/">www.eparcel.kz.</a> временные изменения в
                            работе сервиса, условия которых являются обязательными для Клиентов. Клиент обязуется
                            самостоятельно отслеживать актуальную информацию в разделах «Новости» и
                            «Вопрос-Ответ». <br/>
                            1.4. Услуги, предусмотренные настоящим Соглашением, предназначены исключительно для
                            физических лиц и оказываются при пересылке товаров для личного пользования. Регистрируясь на
                            сайте <a href="http://www.eparcel.ru/">www.eparcel.kz</a>, Клиент понимает, заверяет,
                            подтверждает и соглашается с тем, что он не
                            будет экспортировать или импортировать Товары, а также использовать услуги Исполнителя в тех
                            странах, экспорт или импорт в отношении которых запрещен или для которых необходимо получать
                            разрешения в уполномоченных органах. <br/>

                            1.5.Определения и Термины

                            В настоящем разделе статье определены значения терминов, которые используются в условиях
                            настоящего Соглашения, заключенного между Клиентами и ТОО « EPARCEL-QAZAQSTAN». <br/>

                            Исполнитель – ТОО «EPARCEL-QAZAQSTAN», а также третьи лица, в том числе таможенные
                            представители, связанные с ТОО «EPARCEL-QAZAQSTAN» договорными отношениями. <br/>

                            Товар – товар, пересылаемый в рамках скоростной перевозки любыми видами транспорта с
                            использованием электронной информационной системы организации и отслеживания перевозок в
                            целях доставки данного товара до Получателя в соответствии с индивидуальной накладной в
                            течение минимально возможного и (или) фиксированного промежутка времени. Товар, принимаемый
                            для оказания услуг по настоящему Соглашению, должен быть полностью оплачен Клиентом.
                            Отправитель – физическое лицо, передавшее Исполнителю товары для доставки.
                            Получатель – получатель товара, доставляемого экспресс-перевозчиком, указанный на лицевой
                            стороне индивидуальной накладной Экспресс-перевозчика. <br/>

                            Клиент – физическое лицо, получатель товара, собственник содержимого Отправления,
                            заключившее с Исполнителем настоящее Соглашение путем его акцепта в порядке, установленном
                            Соглашением. <br/>

                            Таможенный представитель / таможенный брокер – юридическое лицо государства-члена ЕАЭС либо
                            иной страны назначения товара, совершающее от имени и по поручению декларанта или иных
                            заинтересованных лиц таможенные операции в соответствии с таможенным законодательством ЕАЭС
                            либо страны назначения. <br/>

                            Декларант – лицо, которое декларирует товары или от имени которого декларируются товары
                            (согласно наименованию в таможенном законодательстве). <br/>

                            Отправление/посылка/экспресс-груз - регистрируемая в системе Исполнителя посылка с товарным
                            вложением, вес которого не превышает 31 кг., отправляемое в индивидуальной упаковке и
                            доставляемое по одной накладной. <br/>

                            Негабаритный экспресс-груз - к негабаритному экспресс-грузу относится посылка, размер одной
                            стороны которой превышает 150,00 см. <br/>

                            Объемный экспресс-груз - экспресс-груз является объемным, если соотношение объемного веса
                            экспресс-груза и физического веса экспресс-груза составляет более чем 1,7. Объемный вес
                            (выражается в килограммах) рассчитывается по формуле: Длина (см) * Высота (см) * Ширина (см)
                            / 5000. <br/>

                            Запрещенные товары – вещи и материалы, принятие к перевозке которых запрещено
                            законодательством любой страны, на/через территорию которой перевозится груз, а также
                            непосредственно перевозчиками и таможенными представителями или Исполнителем с указанием
                            перечня товаров на сайте. <br/>

                            «Личный кабинет» – веб-страница по адресу <a href="http://www.eparcel.ru/">www.eparcel.kz</a>,
                            содержащая информацию о Клиенте,
                            доступных Клиенту Услугах, тарифах, предоставляющая возможность осуществлять заказ услуг, их
                            изменение, контроль исполнения заказа, а также статистическую информацию об объеме
                            полученных Услуг. Доступ к вышеуказанным данным и функциям осуществляется с помощью
                            аутентификационных данных путем введения логина и пароля Клиента. <br/> <br/>

                            Сведения о присвоенных Клиенту идентификаторах (логин и пароль) относятся к конфиденциальным
                            данным и не подлежат передаче Клиентом третьим лицам. Логин и пароль доступа к Личному
                            кабинету генерируется Клиентом самостоятельно при регистрации на сайте www.eparcel.kz. <br/>

                            Клиент обязан обеспечить конфиденциальность и сохранность логина и пароля Клиента, и иных
                            данных необходимых для доступа в Личный кабинет. Действия, совершенные в Личном кабинете,
                            доступ в который осуществлён с использованием логина и пароля Клиента, признаются
                            действиями, совершенными Клиентом лично и/или его уполномоченным представителем от имени и в
                            интересах Клиента. При этом Клиент несет ответственность за любые (в том числе
                            несанкционированные) действия третьих лиц, имевших место в Личном кабинете, доступ к
                            которому осуществлен с использованием логина и пароля Клиента, а также за последствия таких
                            действий. В случае возникновения подозрений на несанкционированные действия третьих лиц
                            доступ в личный кабинет может быть временно приостановлен до повторной идентификации
                            Клиента. <br/>

                            Клиент обязуется не высылать, не передавать, не воспроизводить, не предоставлять или не
                            использовать в коммерческих целях в любом виде информацию, программное обеспечение или
                            другие материалы, полностью или частично полученные посредством Личного кабинета. <br/>

                            Руководствуясь ст.152 ГК РК, ст. 380 ГК РК, Исполнитель и Клиент согласовали использования
                            аналога собственноручной подписи. Использование простой электронной подписи осуществляется в
                            порядке, предусмотренном действующим законодательством Республики Казахстан. Использование
                            логина и пароля, при входе в Личный кабинет, признается простой электронной подписью.
                            Условия Соглашения, принятые Клиентом посредством подписания простой электронной подписью,
                            признаются равнозначным документу на бумажном носителе, подписанному собственноручной
                            подписью надлежаще уполномоченного представителя Клиента. Пользователь личного кабинета
                            принимает на себя исполнение всех обязательств, вытекающих из документов и/или условий,
                            подписанных простой электронной подписью. <br/>

                            В том числе, но не ограничиваясь, Стороны признают юридическую силу документов, информации и
                            сообщений, направленных посредством Личного кабинета Клиента на сайте Исполнителя, а также
                            признают юридическую силу любых действий по выбору и заказу соответствующих услуг в Личном
                            кабинете Клиента. Формирование и направление Клиентом в адрес Исполнителя электронного
                            сообщения посредством нажатия кнопки в Личном кабинете, Стороны признают подписанными лично
                            Клиентом, дачей им поручения Исполнителю, что является равнозначным собственноручной подписи
                            Клиента. <br/>

                            Исполнитель и Клиент соглашаются с тем, что Cторонами могут быть изготовлены (распечатаны)
                            копии подписанных аналогом собственноручной подписи электронных сообщений на бумажном
                            носителе, которые удостоверяются подписью уполномоченного лица и являются бесспорным
                            доказательством факта направления электронного сообщения с использованием Личного кабинета,
                            а также подтверждают соответствие электронного сообщения содержанию копии электронного
                            сообщения, изготовленного (распечатанного) на бумажном носителе.<br/>
                        </p>
                        <h1>2. Предмет соглашения</h1> <br/>
                        <p>
                            2.1. Исполнитель предоставляет Клиенту, прошедшему процедуру регистрации, комплексную услугу по приему, обработке и доставке (вручению) посылок, адресату, указанному Клиентом при заказе услуг в Личном кабинете на сайте. Услуги оказываются в порядке и на условиях, указанных в настоящем Соглашения, с учетом действующих в стране перевозки правил таможенного оформления Товаров и перемещения внутри страны перевозки (включая правила транзитных перевозок). <br/> <br/>
                            2.2. Комплекс включает в себя следующие услуги по организации пересылки экспресс-грузов/посылок: <br/>
                            <ul>
                                <li>Предоставление адреса на территории соответствующей страны, для получения входящего
                                    экспресс-груза/посылок Клиента. Выбор адреса для получения входящего
                                    экспресс-груза/посылок осуществляется Клиентом в Личном кабинете из перечня стран,
                                    на территории которых соответствующие услуги предоставляются;
                                </li>
                                <li>Пересылка экспресс-груза/посылок: приём и обработка входящего экспресс-груза,
                                    обработка экспресс-груза перед отправкой, объединение (консолидация) входящих
                                    экспресс-грузов, хранение, упаковка входящих экспресс-грузов в посылку (упаковка
                                    производится таким образом, чтобы максимально обеспечить сохранность содержимого
                                    посылки в условиях, обычных для применяемого вида транспортировки. Вес посылки при
                                    осуществлении работ по упаковке может измениться);
                                </li>
                                <li>
                                    Отправка посылок в страну Получателя. Организация процессов международной экспресс-перевозки посылки до страны места доставки (включая заключение от своего имени, но в интересах Клиента и за его счет договоров на экспресс-перевозку, а также договоров с уполномоченными компаниями на проведение всех необходимых таможенных процедур вывоза и ввоза товаров) и таможенного оформления в стране назначения;
                                </li>
                                <li>Организация доставки посылки по территории страны Получателя с использованием курьерских служб, расположенных в этой стране;</li>
                                <li>Сопровождение процесса пересылки информированием Получателя о ходе доставки</li>
                            </ul>
                            <p>
                                Акцептом (полным и безоговорочным принятием условий настоящей Публичной оферты и заключение настоящего Соглашения), а также согласие Клиента с настоящими условиями перевозки и таможенного сопровождения является любое из следующих действий Клиента:
                            </p>
                            <ul>
                                <li>Предоставление Клиентом своих персональных данных (в т.ч. копии удостоверения
                                    личности, адреса места жительства, телефона, ИИН) через интернет-сайт контрагента
                                    Исполнителя/Агента, содержащий ссылку на данную оферту, и/или предоставление
                                    согласия на обработку персональных данных и согласия с настоящей офертой путем
                                    загрузки на сайте www.eparcel.kz копии документа, удостоверяющего личность и
                                    проставления отметки «V» на странице  <a
                                        href="http://passport.eparcel.kz">http://passport.eparcel.kz</a> о принятии условий соглашения
                                    об обработке персональных данных.
                                </li>
                                <li>Регистрация Клиента на сайте по адресу www.eparcel.kz.</li>
                            </ul>
                            <p>Клиент принимает условия данного Соглашения, в том числе условия по перевозке и таможенному сопровождению, если он вручает товар Экспресс-перевозчику от своего имени или от имени другого лица, которое имеет права на товар, независимо от того, подписали Клиенты индивидуальную накладную Экспресс-перевозчика или нет.
                                Клиент обязан предоставлять полную и достоверную информацию о себе и заказах, в том числе посредством заполнения информации в личном кабинете. Клиент обязан оформлять Заказы у Продавца лично, от своего имени. Информация о Клиенте в Заказе должна совпадать с данными, представленными Клиентом Исполнителю на сайте www.eparcel.kz.</p>

                            2.4. Условия настоящего Соглашения распространяются также на всех лиц, которых Исполнитель использует или с которыми заключает договоры для приема, транспортировки и доставки товара, таможенного сопровождения, и могут применяться указанными лицами, таможенными представителями, а также сотрудниками, управляющими и агентами.
                        </p>
                        <h1>3. Тарифы и порядок оплаты</h1>
                        <p>
                            3.1. Тарифы на доставку с адреса, предоставленного Клиенту для получения входящего
                            экспресс-груза/посылок в соответствующей стране, на адрес Получателя, стоимость услуг,
                            установленная для негабаритного и/или объемного экспресс-груза, а равно и планируемые сроки
                            доставки экспресс-грузов, можно предварительно рассчитать при помощи калькулятора на сайте
                            по адресу www.eparcel.kz. Расчеты калькулятора являются примерными, носят
                            информационный/справочный характер и не имеют силы оферты.
                            Итоговая стоимость услуг, оказываемых в соответствии с настоящим Соглашением, и подлежащая
                            оплате Клиентом, устанавливается в Личном кабинете Клиента, по итогам обработки входящего
                            экспресс-груза. Итоговая стоимость услуг, подлежащая оплате, определяется при присвоении
                            экспресс-грузу статуса «ожидает отправки» в Личном кабинете Клиента. <br/> <br/>
                            3.2. Оплата Клиентом услуг осуществляется путем пополнения баланса в Личном кабинете
                            Пользователя и последующего списания Исполнителем стоимости услуг, заказанных Клиентом.
                            Личный счет, на котором отображается баланс Клиента, формируется при регистрации Клиента на
                            сайте Исполнителя www.eparcel.kz в Личном кабинете. Личный счет Клиента может быть
                            использован Клиентом исключительно в целях оплаты услуг по настоящему Соглашению. Пополнение
                            личного счета Клиента возможно способами, указанными в разделе «Баланс» по адресу
                            www.eparcel.kz. При оплате услуг Клиент одновременно обязуется оплачивать любые возникающие
                            в связи с этим комиссии банков-посредников и/или платежных систем. <br/> <br/>
                            3.3. Оплата услуг может быть списана с баланса Клиента автоматически, если Клиент перед
                            подтверждением посылки выразил свое согласие на это действие, путем проставления
                            соответствующей отметки в Личном кабинете. В этом случае сразу после взвешивания
                            сформированной посылки, стоимость доставки будет списана с баланса Клиента (при условии, что
                            на балансе Клиента имеется достаточная сумма). <br/> <br/>
                            3.4. В случае если Клиент отказывается от автоматической оплаты, после взвешивания и
                            измерения габаритов посылки Исполнителем, Клиенту будет выставлен счет к оплате. После этого
                            Клиент должен подтвердить списание суммы с его баланса или, если денежных средств на балансе
                            недостаточно, пополнить баланс. Счет к оплате выставляется в тенге по курсу Национального
                            банка Республики Казахстан к соответствующей валюте, установленному на последний день
                            предыдущего месяца, если иной курс или порядок его определения не указан на официальном
                            сайте Исполнителя. <br/> <br/>
                            3.5. Если Клиент отказывается от оплаты доставки посылки, посылка хранится на складе в
                            стране отправления соответствующего экспресс-груза в течение 10 (десяти) календарных дней с
                            момента формирования счета на оплату. Хранение исходящей посылки со статусом «ожидает
                            оплаты»: 10 (десять) дней бесплатно. На 11 (одиннадцатый) день и далее взимается плата за
                            хранение за каждый календарный день в соответствии с установленными тарифами. Максимальный
                            срок хранения посылки - 30 (тридцать) календарных дней. Если по истечении срока оплата не
                            произведена, Исполнитель имеет право полностью утилизировать посылку, без предоставления
                            каких-либо актов и подтверждающих документов. Тарифы указываются Исполнителем на сайте
                            www.eparcel.kz. <br/> <br/>
                            3.6. При поступлении посылки на склад в стране отправления, срок бесплатного хранения
                            посылки составляет 45 (сорок пять) календарных дней с момента поступления товара на склад.
                            Дальнейшее хранение - согласно тарифам. В случае если нахождение Посылки на складе сроком
                            более 75 (семидесяти пяти) календарных дней не было оплачено Клиентом, Исполнитель имеет
                            право полностью утилизировать посылку, без предоставления каких-либо актов и подтверждающих
                            документов. <br/> <br/>
                            3.7. Обязанность оплаты импортных пошлин, налогов и других сборов, которая может возникнуть
                            в стране назначения/отправления, ложится на Получателя, который оплачивает их в момент
                            получения посылки. <br/> <br/>
                            3.8. Оплата предоставляемых услуг может производиться третьими лицами по поручению Клиента,
                            в том числе и оплата услуг таможенного представителя. <br/> <br/>
                            3.9. При объявлении стоимость экспресс-груза, подлежит уплате сбор за объявленную стоимость,
                            который зависит от стоимости товара, указанной Клиентом в Личном кабинете, и включается в
                            общую стоимость услуг. Размеры дополнительного сбора за объявление стоимости Отправления,
                            указаны на сайте Исполнителя. <br/> <br/>
                            3.10. При оказании Исполнителем услуг в отношении негабаритного и/или объемного
                            экспресс-груза, применяются следующие условия расчета стоимости услуг: <br/>
                            <ul>
                                <li>в отношении негабаритного экспресс-груза – устанавливается дополнительный сбор в
                                    размере 60% от стоимости доставки;
                                </li>
                                <li>в отношении объемного экспресс-груза – устанавливается дополнительный сбор в размере
                                    50% от стоимости доставки одного килограмма экспресс-груза за каждый килограмм
                                    превышения объемного веса над физическим весом экспресс-груза;
                                </li>
                                <li>в случае, когда экспресс-груз по своим параметрам относится и к категории
                                    негабаритного груза, и к категории объемного – при расчете итоговой стоимости услуг
                                    применяются оба вида дополнительных сборов, установленных настоящим пунктом.
                                </li>

                            </ul>
                            Размер дополнительного сбора, подлежащего оплате Клиентом в соответствии с условиями
                            настоящего пункта, рассчитывается в Личном кабинете Клиента после завершения процедуры
                            «регистрация» в отношении соответствующего экспресс-груза. <br/> <br/>
                            3.11. Если в ходе оказания услуг Клиенту требуется пересылка, возврат или иное перемещение
                            экспресс-груза, Клиенту может быть выставлен счет в Личном кабинете напрямую от лица,
                            оказывающего соответствующие услуги, который должен быть оплачен Клиентом. Расходы по
                            таможенному оформлению и оплате таможенных пошлин Клиент также несет самостоятельно. <br/>
                            <br/>
                            3.12. В случае некачественного оказания услуг возврат денежных средств, уплаченных Клиентом
                            Исполнителю за оказание услуг, осуществляется по письменному заявлению Клиента с указанием
                            ФИО, ИИН, номера накладной, суммы, согласованной Исполнителем к возмещению и банковских
                            реквизитов. Заявление должно быть подписано Клиентом собственноручно. Сумма уплаченного
                            дополнительного сбора за объявленную стоимость возврату не подлежит. <br/>

                            <h1>4. Порядок исполнения Соглашения сторонами</h1>
                            4.1. Условия таможенного сопровождения: <br/>
                            <ul>
                                <li>Агентом Клиента в целях осуществления таможенного оформления от его имени в стране
                                    назначения выступает любое юридическое лицо включенное в реестр таможенных
                                    представителей. Клиенты принимают (безоговорочный акцепт) оферту таможенного
                                    представителя, размещенную на сайте <a href="https://cdek.kz/ru/offer">www.eparcel.kz.</a>.
                                    Клиенты также
                                    соглашаются с тем, что Экспресс-перевозчик/Агент/Исполнитель может выступать
                                    номинальным грузополучателем с единственной целью, назначения таможенного
                                    представителя для проведения таможенного оформления.
                                </li>
                                <li>В случае требования таможенными органами предоставления дополнительных документов с
                                    целью подтверждения сведений, заявленных в таможенных декларациях на ввоз товаров,
                                    доставляемых Экспресс-перевозчиком, или права Экспресс-перевозчика осуществлять
                                    таможенное оформление, предоставление требуемых документов производится Клиентами за
                                    собственный счет.
                                </li>
                                <li>До момента подписания Клиентами индивидуальной накладной Экспресс-перевозчика
                                    таможенный представитель осуществляет таможенное сопровождение от их имени, действуя
                                    в их интересах.
                                </li>
                                <li>Клиенты обязуются предоставлять полную и достоверную информацию в отношении ввоза
                                    товаров. Клиенты должны знать, что в случае предоставления недостоверной или
                                    заведомо ложной информации о товаре или его содержимом к ним может быть предъявлен
                                    гражданский иск и/или они могут быть привлечены к административной или уголовной
                                    ответственности, в связи с чем, к ним могут быть применены виды наказания в виде
                                    штрафов, конфискации и продажи товара Клиентов. Экспресс-перевозчик может на
                                    добровольной основе оказать содействие в осуществлении таможенного оформления и
                                    других процедур, но весь риск будет отнесен на счет Клиента. Клиент обязуется
                                    возместить Экспресс-перевозчику убытки и оградить его от претензий, которые могут
                                    возникнуть в связи с предоставленной им информацией, а также от любых издержек,
                                    которые Экспресс-перевозчик может понести в связи с вышеизложенным, а также оплатить
                                    любые административные расходы, связанные с оказанием услуг, предусмотренных
                                    настоящими условиями.
                                </li>
                                <li>Для отправки посылок экспресс-доставкой необходимо предоставление ИИН, паспортных
                                    данных, ссылок на товар в интернет-магазине, где ввозимый товар был приобретен, а
                                    также скан-копии документа, удостоверяющего личность, скриншот заказа, счет на
                                    оплату товара (инвойс), выписки по банковской карте, а также иные документы по
                                    запросу. В случае предоставления неверной информации Экспресс-перевозчик имеет право
                                    произвести реэкспорт посылки (обратный вывоз). Все затраты по реэкспорту и повторной
                                    доставке посылки оплачиваются Клиентом через Личный кабинет, путем предоставления
                                    Исполнителю право на списание соответствующих расходов с личного счета Клиента, в
                                    случае отсутствия денежных средств в личном счете, путем взыскания в судебном
                                    порядке с Клиента.
                                </li>
                                <li>Любые таможенные платежи, налоги (включая, но не ограничиваясь, налогом на
                                    добавленную стоимость, если он подлежит уплате), которые таможенный представитель
                                    может понести в результате действий таможенных или других государственных органов
                                    власти, или в связи с тем, что Клиенты не смогут предоставить надлежащий перечень
                                    документов, и/или получить необходимую лицензию или разрешение, будут выставлены к
                                    оплате Клиентам. Стоимость хранения и другие расходы, которые Экспресс-перевозчик
                                    может понести в результате действий таможенных или других государственных органов
                                    власти, или в связи с тем, что Клиенты не смогут предоставить надлежащий перечень
                                    документов, и/или получить необходимую лицензию или разрешение, будут выставлены к
                                    оплате Клиентам. Клиент берет на себя оплату таможенных платежей и пошлин, а также
                                    административных расходов, связанных с дополнительной работой, и любых других
                                    дополнительно понесенных издержек, в случае, если таможенный представитель
                                    выставляет счет Получателю, а Получатель отказывается оплатить таможенные платежи и
                                    пошлины. Указанные расходы оплачиваются Клиентом через Личный кабинет, путем
                                    предоставления Исполнителю право на списание соответствующих расходов с личного
                                    счета Клиента, в случае отсутствия денежных средств в личном счете, путем взыскания
                                    в судебном порядке с Клиента.
                                </li>
                                <li>Клиент согласен с правом Экспресс-перевозчика, таможенного представителя,
                                    уполномоченных государственных органов, включая таможенные, в целях соблюдения
                                    законодательства или устранения возможного вреда другим товарам, а также при
                                    подозрении на недопустимое или опасное вложение вскрывать и проверять товары.
                                </li>
                                <li>Клиент согласен с правом Экспресс-перевозчика или назначенного таможенного
                                    представителя от имени и по поручению Клиентов присутствовать при таможенном
                                    досмотре и осуществлении других таможенных операций и формальностей.
                                </li>
                                <li>Клиент согласен, что пересчет стоимости товара в иностранной валюте в валюту РК для
                                    целей определения таможенной стоимости товара производится по курсу валют,
                                    устанавливаемому в соответствии с законодательством РК, действующему на день
                                    регистрации таможенным органом таможенной декларации, если иное не установлено
                                    законодательством РК.
                                </li>
                                <li>Клиент согласен, что при заказе товара стоимостью менее 2 (двух) денежных единиц
                                    (долларов, евро, лир, или иной валюты в зависимости от страны заказа) за единицу
                                    товара таможенная стоимость товара для целей таможенного оформления устанавливается
                                    не менее 2 (двух) денежных единиц соответствующей страны заказа товара.
                                </li>
                            </ul>
                            Клиент обязуется не включать в Заказы и не отправлять на адрес склада в стране отправления
                            товары, запрещенные к пересылке законодательством страны отправления экспресс-груза/посылки,
                            а также законодательством страны назначения. Клиент обязуется самостоятельно ознакомиться с
                            указанными требованиями и ограничениями, а также с правилами вывоза/ввоза и таможенного
                            оформления, таможенными ограничениями, в том числе с перечнем запрещенных к ввозу товаров, а
                            также с санкционными ограничениями. Оформляя заказ, Клиент подтверждает, что ознакомлен с
                            таможенными правилами (в том числе в части, касающейся запрещенных к вывозу/ввозу товаров),
                            санкционными ограничениями, а также несет всю ответственность в случае невозможности
                            исполнения Исполнителем Заказа Клиента по причине несоблюдения таможенного законодательства
                            или санкционных ограничений и возмещает все убытки и расходы Исполнителю. В случае
                            предоставления недостоверной или заведомо ложной информации об экспресс-грузе или его
                            содержимом, таможенным органом страны назначения могут быть применены санкции в виде
                            конфискации партии экспресс-грузов. <br/> <br/>
                            4.3. Не принимаются к доставке товары, которые признаны опасными, а также могут причинить
                            вред товарам других Клиентов. Перечень таких товаров размещен в Личном кабинете на сайте
                            Исполнителя в разделе «Запрещенные к пересылке товары».<br/> <br/>
                            4.4. Исполнитель не несет ответственности за товар, направленный на адрес склада, указанный
                            в Личном кабинете Клиента, в соответствующей стране, но на этот адрес не доставленный.
                            Исполнитель не несет ответственности за заказы, на которых не полностью или неверно указан
                            адрес склада, не зависимо от того, произошло это по вине Отправителя или Клиента.
                            Ответственность Исполнителя за товары Клиента наступает только с момента передачи
                            почтовой/курьерской службой товаров на склад отправления. <br/> <br/>
                            4.5. Исполнитель несет ответственность только за фактический принятый вес товаров, без учета
                            веса, указанного продавцами/отправителями либо на сайтах почтовых служб, доставивших товар
                            на склад в стране отправления для последующей пересылке Клиенту. <br/> <br/>
                            4.6. Исполнитель обязуется: <br/>
                            <ul>
                                <li>передать с использованием интеграции программного обеспечения в Личный кабинет
                                    информацию о приемке и регистрации входящего экспресс-груза в течении 3 (трех)
                                    рабочих дней, не считая дня получения входящего экспресс-груза от
                                    продавца/отправителя;
                                </li>
                                <li>на этапе регистрации экспресс-груза провести замеры поступившего груза (длина,
                                    ширина, высота), для установления категории экспресс-груза (обычный, негабаритный,
                                    объемный);
                                </li>
                                <li>обработать экспресс-груз Клиента (упаковка, маркировка посылки) в течении 3 (трех)
                                    рабочих дней с момента оформления Клиентом в Личном кабинете операции «исходящая
                                    посылка» (в указанном случае экспресс-грузу присваивается статус «ожидает
                                    отправление») и перевести посылку в статус «ожидает оплаты».
                                </li>
                            </ul>
                            4.7. В рамках настоящего Соглашения не принимаются к пересылке и доставке в страну
                            назначения товары, продавцами которых являются физические лица, не являющиеся владельцами
                            интернет-магазинов. Исполнитель не оказывает услуги по настоящему Соглашению в случаях
                            проведения ремонта или замены товаров Отправителем. Исполнитель также не оказывает услуги по
                            организации пересылки экспресс-грузов/посылок, получаемых Клиентом на безвозмездной основе
                            (например, призы в розыгрышах). <br/> <br/>
                            4.8. Также Клиент вправе заказать дополнительные услуги: <br/>
                            <ul>
                                <li>возврат товара продавцу, при этом оплачивается стоимость доставки до продавца,</li>
                                <li>фотографирование товара, с предоставлением от 1 до 3 фотографий Клиенту, путем их
                                    отправки на электронную почту,
                                </li>
                                <li>упаковка посылок перед отправкой Клиенту осуществляется с пожеланиями Клиента:
                                    гофра-коробка –по тарифу, указанному на сайте Исполнителя, пакет или коробка –
                                    стоимость упаковки включена в стоимость доставки. которые Клиент может указать при
                                    отправке его исходящей посылки на сборку
                                </li>
                                <li>хранение посылки на складе Исполнителя – исчисляется в календарных днях, начиная с
                                    46 дня, с момента поступления посылки Исполнителю,
                                </li>
                                <li>расконсолидация поступившего товара на отдельные посылки.</li>
                            </ul>
                            Исполнитель, вправе вводить дополнительные услуги, путем предоставления возможности заказа
                            соответствующей услуги в Личном кабинете Клиента. Расценки на дополнительные услуги
                            приведены по адресу: www.eparcel.kz. <br/>
                            4.9. Доставка экспресс-грузов осуществляется по принципу ограниченной ответственности
                            Исполнителя (раздел 5.6 настоящего Соглашения). Если Клиенту недостаточны выплаты
                            Исполнителя в ограниченном размере, установленном разделом 5.6 настоящего Соглашения, в
                            случае утраты/повреждения Отправления, Клиенту необходимо объявить стоимость Отправления и
                            оплатить дополнительный сбор до момента отправки посылки. Под объявленной стоимостью
                            понимается заявленная Клиентом стоимость вложения посылки, указанная Клиентом в Личном
                            кабинете, при оформлении заказа. <br/>
                            В случае не указания Клиентом объявленной стоимости, размер возмещения Исполнителем
                            причиненного ущерба в случае утраты/повреждения экспресс-груза, определяется в соответствии
                            с разделом 5.6 настоящего Соглашения. <br/>
                            Исполнитель по умолчанию предлагает Клиенту указать объявленную стоимость экспресс-груза, с
                            уплатой дополнительного сбора. <br/>
                            Объявленная стоимость в случае утраты/повреждения экспресс-груза должна быть подтверждена
                            Клиентом документально. <br/>
                            4.10. Срок доставки экспресс-груза в страну назначения исчисляется от даты создания
                            накладной на отправку в Личном кабинете Клиента. Накладная на доставку формируется
                            автоматически, после оплаты Клиентом услуг по соответствующему экспресс-грузу (в том числе
                            услуг доставки). При расчете срока доставки не учитывается дата создания накладной, дата
                            вручения посылки Клиенту, выходные и праздничные дни, в том числе, на территории стран,
                            через которые осуществляется доставка. Сроки доставки указываются на сайте www.cdek.kz и
                            рассчитываются индивидуально в зависимости от страны отправления и места доставки. <br/>
                            <br/>
                            4.11. Клиент обязан оплачивать товары и совершать иные платежи лично, с использованием
                            принадлежащих ему платежных средств. Клиент подтверждает, что денежные средства, оплаченные
                            Клиентом за товар или услуги Исполнителя, а также за дополнительные услуги, таможенные
                            платежи и пошлины и т.п., принадлежат Клиенту на законном основании и у третьих лиц нет
                            прав/притязаний на указанные денежные средства. В случае возникновения претензий со стороны
                            третьих лиц относительно денежных средств, которыми Клиентом производился расчет, Клиент
                            обязуется самостоятельно, своими силами и средствами, без привлечения Исполнителя,
                            урегулировать такие претензии, а также возместить все убытки Исполнителю/привлеченным им для
                            исполнения настоящего Соглашения третьим лицам. В случае отказа от товара и/или возврата
                            денежных средств до получения товара Клиент обязан незамедлительно уведомить Исполнителя для
                            принятия совместного решения о дальнейших действиях с товаром, его таможенным оформлением
                            при необходимости и проведению взаиморасчетов. В случае выявления факта возврата Клиенту
                            денежных средств за товар до момента окончания оказания услуг по Соглашению, о котором
                            Исполнитель не был предупрежден Клиентом, в целях предотвращения мошенничества Исполнитель
                            вправе удержать товар и/или приостановить оказание услуг до момента выяснения всех
                            обстоятельств дела и проверки полномочий Клиента на распоряжение товаром. Исполнитель не
                            несет ответственности за любые убытки, возникшие в результате мошеннических действий
                            Отправителя, Получателя или третьих лиц, не являющихся сотрудниками Исполнителя. <br/> <br/>
                            4.12. В случае реэкспорта посылки Клиента таможенными органами по его вине, Исполнитель не
                            отвечает за дальнейшее перемещение экспресс-груза, его хранение, возврат Отправителю и т.д.
                            Отказ в выпуске товара или иные распорядительные действия с товаром, произведенные
                            таможенным органом по вине Клиента, означают освобождение Исполнителя от принятых на себя
                            обязательств в рамках настоящего Соглашения, при этом обязательства Исполнителя считаются
                            выполненными в полном объеме и надлежащего качества. <br/> <br/>
                            4.13. В случае, если по истечению 45 дней с момента присвоения
                            Отправлению/посылке/экспресс-грузу последнего статуса, Отправление/посылка/экспресс-груз не
                            найдены, соответствующее Отправление/посылка/экспресс-груз считается утраченным.
                            Отслеживание производится Клиентом на сайте Исполнителя. <br/> <br/>
                            4.14. В случае письменного отказа Получателя и/или Клиента от получения
                            Отправления/посылки/экспресс-груза, либо в случае невостребованности
                            Отправления/посылки/экспресс-груза (в т.ч. в случае отсутствия письменного указания Клиента
                            о пересылке/утилизации и проч. Отправления/посылки/экспресс-груза) в течение 5 (пяти)
                            месяцев с момента присвоения Отправлению/посылке/экспресс-грузу на сайте Исполнителя по
                            ссылке https:// www.eparcel.kz/tracking статуса «Не вручен», право собственности на
                            соответствующее Отправление/посылку/экспресс-груз переходит к Исполнителю. <br/>

                        </p>
                        <h1>5. Ответственность</h1>
                        <p>
                            5.1. Исполнитель не несет ответственность за неисполнение обязательств перед Клиентом,
                            произошедших по причине: стихийные бедствия: землетрясения, циклоны, ураганы, наводнения,
                            пожары, эпидемии, туманы, снегопады или морозы; форс-мажорные обстоятельства, включая,
                            помимо прочего: войны, катастрофы, действия враждебно настроенных лиц, забастовки, эмбарго,
                            реализации рисков, характерных для воздушной перевозки, местных конфликтов и акций
                            гражданского неповиновения, авиа- или авто- катастрофы; перебои в работе сетей местного или
                            национального воздушного и наземного сообщения, технические неисправности на транспорте и в
                            оборудовании; скрытые недостатки или врожденные дефекты содержимого груза; преступные
                            действия третьих лиц, таких как хищение и поджог; действия или бездействие таможенных
                            органов, служащих авиалиний и аэропортов, или государственных чиновников; того, что
                            содержимое груза является запрещенным предметом, в том числе, если Экспресс-перевозчик
                            принял товар к перевозке по ошибке. <br/> <br/>
                            Если повреждение произошло по причине недостаточной либо некачественной упаковки, либо при
                            отсутствии на упаковке знаков, предписывающих определенный режим обращения при
                            перевозке/перевалке данного товара. Исполнитель не несет ответственности, если факт порчи
                            товара связан с несоблюдением определенного температурного режима, нормативной утечкой,
                            потерей веса, объема или нормативного износа, самовозгорания, брожения, гниения, старения,
                            усушки, коррозии и других естественных свойств товара. <br/> <br/>
                            5.1.1. Клиент понимает, что заключает настоящее Соглашение в период введения санкций,
                            ограничений государственными (в т.ч. таможенными) органами различных стран, постоянного
                            изменения логистических маршрутов, курса валют и иных обстоятельств, влияющих на надлежащее
                            выполнение Исполнителем принятых на себя обязательств и осознает риски, связанные с
                            приобретением товаров в иностранных интернет-магазинах и заказом их доставки с
                            использованием сервиса Исполнителя. Сроки доставки могут быть увеличены Исполнителем в
                            одностороннем порядке без применения к нему ответственности и предоставления подтверждающих
                            документов. Исполнитель не отвечает за действия государственных и иных органов, в т.ч. на
                            территории других стран, препятствующие своевременному и качественному выполнению
                            обязательств со стороны Исполнителя. <br/> <br/>
                            5.2. Исполнитель не несет ответственности за любые расходы Клиента, прямой или косвенный
                            ущерб, причиненный Клиенту в результате (без ограничений): возможных ошибок, сбоев,
                            перерывов в работе включая блокировку Личного кабинета, удаления файлов, изменения функций,
                            дефектов, задержек при передаче данных или их обработке. <br/> <br/>
                            5.3. Исполнитель не несет ответственности за сроки доставки товара от Отправителя на склад
                            Исполнителя в соответствующей стране. <br/> <br/>
                            5.4. Исполнитель не несет ответственности за качество и комплектацию полученных на свой
                            склад товаров. Исполнитель не проверяет товар, полученный от Отправителя на соответствие
                            заявленным Продавцом характеристикам, на отсутствие явного или скрытого брака, и в случае
                            ошибки последнего, не несет ответственности за несоответствие товара. <br/> <br/>
                            5.5. Ответственность за правильность и полноту сведений, указанных в инвойсе, и декларации,
                            оформленной на основании данных, предоставленных Клиентом в Личном кабинете на сайте
                            www.eparcel.kz, несет Клиент. <br/> <br/>
                            5.6. При утрате, недостаче экспресс-груза, перевозимого с объявленной ценностью, возмещению
                            подлежит сумма объявленной ценности или доля объявленной ценности, соответствующую
                            утраченной части груза. <br/> <br/>
                            5.6.1. Размер ответственности Исполнителя относительно экспресс-груза, стоимость которого не
                            была объявлена и за которое не взимался сбор за объявленную стоимость: <br/>
                            <ul>
                                <li>В случае полной утраты или полного повреждения (порчи) Отправления – в размере
                                    стоимости услуг Исполнителя по доставке соответствующего Отправления и
                                    дополнительной компенсации в размере реально причиненного ущерба, но не более 17
                                    000,00 тенге.
                                </li>
                                <li>В случае частичного повреждения (порчи) или утраты части Отправления – в размере
                                    части стоимости услуг Исполнителя по доставке всего экспресс-груза пропорционально
                                    доле платного веса поврежденного / утраченного вложения в общем платном весе всего
                                    экспресс-груза и дополнительной компенсации в размере реально причиненного ущерба,
                                    но не более 17 000,00 тенге.
                                </li>
                            </ul>
                            5.6.2. Размер ответственности Исполнителя относительно экспресс-груза, стоимость которого
                            была объявлена и за которое взимался сбор за объявленную стоимость: <br/>
                            <ul>
                                <li>В случае полного повреждения (порчи) или полной утраты Отправления – в размере
                                    стоимости вложения, находившегося в соответствующей Отправления, но не более размера
                                    объявленной стоимости, указанной в накладной, и стоимости услуг по доставке
                                    соответствующего Отправления.
                                </li>
                                <li>В случае утраты (недостачи) части Отправления или частичного его повреждения – в
                                    размере части стоимости услуг Исполнителя по доставке всего Отправления
                                    пропорционально доле платного веса поврежденного / утраченного вложения в общем
                                    платном весе всего Отправления и дополнительной компенсации в размере стоимости
                                    недостающего вложения или суммы, на которую понизилась стоимость поврежденного
                                    вложения, но не более размера объявленной стоимости, указанной в накладной, и
                                    стоимости услуг по доставке соответствующего Отправления.
                                </li>
                            </ul>
                            5.6.3. В случае не объявления Клиентом объявленной стоимости Отправления и не оплаты им
                            обязательного сбора за объявленную стоимость Отправления, Исполнитель несет ответственность
                            в размере, установленном настоящим разделом. <br/> <br/>
                            5.6.4. Сумма возмещения в связи с утратой/повреждением Отправления зачисляется Исполнителем
                            на личный счет Клиента в его личном кабинете либо при наличии письменного заявления, с
                            указанием ФИО, ИИН, номера накладной, суммы, согласованной Исполнителем к возмещению и
                            банковских реквизитов. Заявление должно быть подписано Клиентом собственноручно. Сумма
                            уплаченного дополнительного сбора за объявленную стоимость возврату не подлежит. В случае
                            если была объявлена стоимость Отправления, для установления и подтверждения реальной
                            стоимости причиненного ущерба Отправлению в ходе доставки, Исполнитель вправе потребовать от
                            Клиента предоставить документы, подтверждающие действительную стоимость Отправления и право
                            собственности Клиента на Отправление. В случае частичного повреждения Отправления Клиентом
                            дополнительно предоставляется заключение экспертной организации и/или документы,
                            подтверждающие стоимость восстановительного ремонта. В случае, если Клиент в течение 1
                            (одного) календарного месяца с момента возникновения у него обязательства по предоставлению
                            соответствующих документов, не предоставляет Исполнителю документы, подтверждающие
                            действительную стоимость Отправления и право собственности Клиента на Отправление, а в
                            случае повреждения Отправления, заключение экспертной организации и/или документы,
                            подтверждающие стоимость восстановительного ремонта, то причиненный ущерб возмещается на
                            условиях и в размере, установленном для Отправлений, стоимость которых не была объявлена и
                            за которые не взимался сбор за объявленную стоимость (п. 5.6.1. настоящего Соглашения).<br/>
                            <br/>
                            5.7. Размер ответственности Исполнителя за нарушение сроков оказания услуг: <br/> <br/>
                            5.7.1. за нарушение сроков, установленных п. 4.6 настоящего Соглашения, Исполнитель
                            уплачивает неустойку в размере 55, 00 (пятьдесят пять) тенге за каждый день просрочки. Сумма
                            неустойки зачисляется Исполнителем на личный счет Клиента в его Личном кабинете. <br/> <br/>
                            5.7.2. за нарушение сроков доставки, установленных п.4.10 настоящего Соглашения, уплачивает
                            неустойку в размере 1% (одного процента) от стоимости услуг по доставке соответствующего
                            экспресс-груза, за каждый рабочий день просрочки, но не более стоимости услуг доставки
                            указанного экспресс-груза. Сумма неустойки зачисляется Исполнителем на личный счет Клиента в
                            его Личном кабинете либо при наличии письменного заявления, с указанием реквизитов
                            банковского счета, перечисляется на банковский счет Клиента. <br/> <br/>
                            5.8. В случае если в результате оказания услуг по вине Исполнителя была допущена пересортица
                            экспресс-груза, экспресс-груз считается утраченным, и Исполнитель несет ответственность в
                            размере, установленном пунктами 5.6.1-5.6.4 настоящего Соглашения.
                            Если пересортица экспресс-груза возникла по вине отправителя/продавца, Исполнитель
                            освобождается от ответственности перед Клиентом.
                            Под пересортицей в рамках настоящего Соглашения понимается одновременная недостача одного
                            наименования/ассортимента вложения и излишек другого наименования/ассортимента. <br/> <br/>
                            5.9. В случае возникновения со стороны Клиента претензий за утерю, повреждение или задержку
                            товара, доставляемого Экспресс-перевозчиком, Клиент должен строго соблюдать правила
                            следующей процедуры, при несоблюдении которой Экспресс-перевозчик оставляет за собой право
                            отказать Клиенту в рассмотрении претензии: <br/> <br/>
                            5.9.1. Клиент вправе предъявить Исполнителю претензию в письменном виде в течение 30-ти
                            календарных дней с момента передачи Клиентом Исполнителю Отправления. Основанием для
                            предъявления претензии в случае повреждения отправления служит Акт, составленный в
                            присутствии представителя Исполнителя в момент доставки Отправления. В случае претензий
                            Клиента по срокам доставки либо утрате Исполнителем Отправления, Акт не составляется. Клиент
                            должен предъявить все документы, касающиеся товара. <br/> <br/>
                            5.9.2. При получении Отправления, доставляемого экспресс-перевозчиком, Клиент делает запись
                            в доставочном листе о повреждении товара, в противном случае Экспресс-перевозчик считает,
                            что доставил Отправление в надлежащем состоянии. Для рассмотрения претензии о повреждении
                            Отправления, доставляемого экспресс-перевозчиком, Клиенты должны представить содержимое
                            отправления и оригинальную упаковку для осмотра. <br/> <br/>
                            5.10. В случае нарушения Клиентом условий настоящего Соглашения, в том числе, но не
                            исключая, предоставление недостоверных данных о себе, включение в Заказ запрещенных товаров,
                            неуплата таможенных пошлин, отказ от оплаты доставки товара или иное нарушение, Исполнитель
                            вправе заблокировать Личный кабинет Клиента, приостановить оказание услуг и/или вернуть
                            Продавцу товары без возмещения убытков Клиента. Такой возврат не будет считаться нарушением
                            Соглашения со стороны Исполнителя. <br/>
                            Убытки, понесенные Исполнителем в результате допущенных Клиентом нарушений, возмещаются
                            Клиентом в полном объеме. <br/> <br/>
                            6. Персональные данные <br/> <br/>
                            Согласие Клиента на обработку его персональных данных, подтверждается Клиентом путем акцепта
                            настоящего Соглашения. Клиент, давая согласия на передачу товара для транспортировки и
                            таможенного оформления, от собственного имени, дает Исполнителю/Экспресс-Перевозчику свое
                            согласие на сбор, хранение, передачу, уничтожение и обработку его персональных данных (а
                            именно: фамилию, имя, отчество, адрес, номер основного документа, удостоверяющего его
                            личность, сведения о дате выдачи указанного документа и выдавшем его органе, номер
                            телефона), в том числе на их обработку и хранение (в том числе в виде копии основного
                            документа, удостоверяющего личность) в информационных системах персональных данных
                            Экспресс-перевозчика. <br/>
                            Согласие выдано на три года, Клиент вправе отозвать настоящее согласие путем направления
                            письменного уведомления в адрес Исполнителя. <br/>
                        </p>
                        <h2>Лицо, уполномоченное Исполнителем на принятие от Клиентов претензий для их последующей передачи Исполнителю, в соответствии с настоящим Соглашением:</h2>
                        <p>
                            ТОО "EPARCEL-QAZAQSTAN" <br/>
                            Адрес: Казахстан, Астана, улица Жұмекен Нәжімеденов, дом 16, кв/офис 4 <br/>
                            БИН (ИИН): 240440042286. <br/>
                        </p>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
