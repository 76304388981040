import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { BrowserRouter as Router, Route, Switch, NavLink as RouterNavLink } from "react-router-dom";
import Header from "components/headers/MainCabinetHeader";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SearchIcon } from "../images/search-icon.svg";
import AnimationRevealPage from "../helpers/AnimationRevealPage"; // Ensure you have the search icon SVG file
import Footer from "components/footers/MainFooterWithLinks";

import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import warehouseIcon from "../images/warehouse.png";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const Container = styled.div`
    ${tw`relative w-full`}
    padding: 0;
    margin: 0;
    box-sizing: border-box;
`;

const InfoContainer = styled.div`
    ${tw`flex w-full items-center justify-between gap-2 mt-4`}
    ${tw`p-4 sm:px-4 md:px-8 lg:px-12 xl:px-16 sm:px-0 md:px-0 lg:px-0 xl:px-0`}
`;

const InfoBox = styled.div`
    ${tw`flex items-center justify-between bg-white shadow-md rounded-lg p-2`}
    border: 1px solid #0ABD19;
    width: auto;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    background-color: ${(props) => (props.isHighlighted ? "#EBFAE5" : "white")};
`;

const InfoText = styled.span`
    ${tw`text-gray-600`}
`;

const IconButton = styled.a`
    ${tw`ml-4 bg-green-500 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;
    margin-left: auto; // Automatically push the button to the end
    margin-top: 0; // Remove top margin
    width: 20px; // Set button width
    height: 20px; // Set button height

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;

const BaseButtonStyles = css`
    ${tw`px-6 py-2 font-semibold rounded-lg shadow-md bg-white text-lg text-gray-600`}
    ${tw`h-10 w-full sm:w-auto`}
    ${tw`m-2`}
    border: 2px solid #0ABD19;

    &:hover {
        ${tw`text-black bg-green-200`}
    }

    &:focus {
        ${tw`text-black bg-green-200`}
    }
`;

const TwoColumn = styled.div`
    ${tw`flex flex-col lg:flex-row lg:items-start max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const LeftColumn = styled.div`
    ${tw`relative w-full text-left mx-auto`}
`;

const RightColumn = styled.div`
    ${tw`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`}
`;

const Heading = styled.h1`
    ${tw`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-4xl leading-tight`}
    margin-bottom: 20px;
    color: #2D2D2D;
`;

const PrimaryButton = styled.button`
    ${BaseButtonStyles}
    ${({ selected }) =>
            selected &&
            css`
            ${tw`bg-green-200 border-green-600 text-black`}
        `}
`;

const ButtonContainer = styled.div`
    ${tw`flex flex-wrap items-center justify-start gap-4`}
    ${tw`p-0`} // Remove padding
    ${tw`sm:px-0 md:px-0 lg:px-0 xl:px-0`} // Ensure no extra padding
    ${tw`mt-8`} // Add top margin
`;

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
    ${tw`relative flex items-center w-full `}
`;
const InputContainer = styled.div`
    ${tw`relative flex-grow flex items-center`}
    margin-right: 1rem;
`;

const SearchInput = styled.input`
    ${tw`pl-10 pr-4 py-3 rounded-full w-full font-medium`}
    height: 20px;
    border: 2px solid #0ABD19;

    &:hover {
        border: 2px solid #0ABD19;
    }

    &:focus {
        border: 2px solid #0ABD19;
    }
`;
const NavLink = styled(RouterNavLink)`
    text-decoration: none;
`;
const SearchIconContainer = styled.div`
    ${tw`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none`}
`;

const AddButton = styled.button`
    ${tw`bg-green-500 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    height: 37px;
    background-color: #0ABD19;
    border: none;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);

    &:hover, &:focus {
        background-color: #025e07;
    }
`;

const InfoMessageBox = styled.div`
    ${tw`flex flex-col items-start p-4 bg-yellow-100 rounded-xl mt-8`}
    width: calc(100% - 2rem);
    max-width: 100%;
    color: #333;
    background-color: #fffbe5;
`;

const InfoMessageHeading = styled.h2`
    ${tw`text-lg font-bold mb-2`}
`;

const InfoMessageText = styled.p`
    ${tw`text-base`}
`;

const BottomButtonsContainer = styled.div`
    ${tw`flex justify-start gap-4 mt-8 sm:px-0 md:px-0 lg:px-0 xl:px-0`}
`;

const BottomButton = styled.button`
    ${tw`w-auto bg-green-500 text-white font-bold py-4 px-6 rounded-full flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;

    &:hover, &:focus {
        transform: scale(1.1);
    }
`;

const IllustrationContainer = styled.div`
    ${tw`flex justify-center lg:justify-end items-center`}
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 600px;

    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 0.5s ease-in-out;
        width: 100%;
        height: 600px;
    }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const Icon1 = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
`;
const HighlightedText = styled.span`
    ${tw`font-bold`}
    color: #0ABD19;
`;

const OutgoingParcels = ({ roundedHeaderButton }) => {
    const [showFirstImage, setShowFirstImage] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState("США");
    const [parcels, setParcels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setShowFirstImage((prev) => !prev); // Toggle between true and false
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    useEffect(() => {
        const fetchParcels = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            const db = getFirestore();

            if (user) {
                const q = query(collection(db, 'parcels'), where("userId", "==", user.uid));
                const querySnapshot = await getDocs(q);

                const fetchedParcels = [];
                querySnapshot.forEach((doc) => {
                    fetchedParcels.push({ id: doc.id, ...doc.data() });
                });

                setParcels(fetchedParcels);
                setLoading(false);
            }
        };

        fetchParcels();
    }, []);

    const filteredParcels = parcels.filter(parcel =>
        (selectedCountry === 'Все склады' || parcel.warehouse === selectedCountry) &&
        (parcel.parcelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            parcel.trackingNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            parcel.storeName.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <AnimationRevealPage>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Container>
                <TwoColumn>
                    <LeftColumn>
                        <Heading>Исходящие посылки</Heading>
                        <Actions>
                            <InputContainer>
                                <SearchIconContainer>
                                    <SearchIcon />
                                </SearchIconContainer>
                                <SearchInput
                                    type="text"
                                    placeholder="Поиск по номеру посылки, трекингу, названию посылки или товара"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <NavLink to="/OutgoingParcelForm">
                                    <AddButton>Добавить посылку</AddButton>
                                </NavLink>
                            </InputContainer>
                        </Actions>
                        <ButtonContainer>
                            <PrimaryButton onClick={() => setSelectedCountry('Все склады')} selected={selectedCountry === 'Все склады'}>Все склады</PrimaryButton>
                            <PrimaryButton onClick={() => setSelectedCountry('США')} selected={selectedCountry === 'США'}>США</PrimaryButton>
                            <PrimaryButton onClick={() => setSelectedCountry('Турция')} selected={selectedCountry === 'Турция'}>Турция</PrimaryButton>
                        </ButtonContainer>
                        <InfoContainer>
                            <InfoBox>
                                <Icon1 src={warehouseIcon} alt="Warehouse Icon" />
                                <InfoText>
                                    На складе:  6шт | 20$ | 20 кг
                                </InfoText>
                            </InfoBox>
                            <IconButton href="#">
                                <PlusIcon />
                            </IconButton>
                        </InfoContainer>

                        <InfoMessageBox>
                                <InfoMessageHeading>У Вас нет исходящих посылок!</InfoMessageHeading>
                                <InfoMessageText>Выберите товар, оформите доставку на адрес склада, добавьте посылку и в этом разделе Вы сможете отслеживать прибытие посылки на наш склад.</InfoMessageText>
                            </InfoMessageBox>
                        <BottomButtonsContainer>
                            <NavLink to="/ExpectedLink">
                                <BottomButton>Добавить ожидаемую посылку</BottomButton>
                            </NavLink>
                            <NavLink to="/PersonalArea">
                                <BottomButton>В профиль</BottomButton>
                            </NavLink>
                        </BottomButtonsContainer>
                    </LeftColumn>
                </TwoColumn>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};

export default OutgoingParcels;
