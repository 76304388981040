import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import Header from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/kz1.png";
import DesignIllustration1 from "../../images/cards2.png";
import DesignIllustration2 from "../../images/cards1.png";
import { useNavigate } from "react-router-dom";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const Container = styled.div`
  ${tw`relative`}
  background-color: #e2ffe2 !important; /* Set background color */
`;

const TwoColumn = styled.div`
  ${tw`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`}
  padding-left: 20px; /* Add left padding */
  padding-right: 20px; /* Add right padding */
`;

const LeftColumn = styled.div`
  ${tw`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`}
`;

const RightColumn = styled.div`
  ${tw`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`}
  max-width: 100%; /* Ensure the column takes the full width */
  position: relative;
`;

const Heading = styled.h1`
  ${tw`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`}
  margin-bottom: 20px;
  animation: ${({ animate }) => animate ? fadeIn : fadeOut} 1s ease-in-out;
`;

const Paragraph = styled.p`
  ${tw`my-2 lg:my-4 text-base xl:text-lg text-black`}
  animation: ${({ animate }) => animate ? fadeIn : fadeOut} 1s ease-in-out;
`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 flex items-center`}
  margin-top: 10px;

  input {
    ${tw`py-4 pl-8 pr-32 sm:py-5 rounded-full border-2 w-full lg:w-[450px] font-medium focus:outline-none transition duration-300`}
    &:hover, &:focus {
      border-color: #0ABD19;
    }
  }

  button {
    ${tw`absolute right-0 h-full bg-green-500 text-white font-bold rounded-full py-1 px-6 flex items-center justify-center leading-none focus:outline-none transition duration-300`}
    background-color: #0ABD19;
    border: none;
    transform: translateX(-10%); /* Move the button more to the left */
    height: calc(100% - 12px); /* Set button height to slightly smaller than the input height */
    &:hover, &:focus {
      transform: scale(1.1) translateX(-10%);
    }
  }
`;

const IllustrationContainer = styled.div`
  ${tw`relative`}
  width: 100%; /* Makes sure the container is full width */
  height: 600px; /* Adjust the height as necessary */
  overflow: hidden; /* Ensure images do not overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    transition: opacity 1s ease-in-out;
    width: 100%; /* Ensure the image fits the container width */
    height: 100%; /* Ensure the image fits the container height */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const HighlightedText = styled.span`
  ${tw`font-bold`}
  color: #0ABD19;
`;

export default ({ roundedHeaderButton }) => {
  const [email, setEmail] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prev => (prev + 1) % 3); // Cycle through 0, 1, and 2
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const handleRegister = () => {
    localStorage.setItem('email', email); // Store email in local storage
    navigate('/RegistrationPage'); // Navigate to the registration page
  };

  const headings = [
    <>Доставка товаров из США и Турции в <HighlightedText>Казахстан</HighlightedText></>,
    <>Различные способы получения посылок для наших клиентов в <HighlightedText>Казахстан</HighlightedText></>,
    <>Выкуп товаров</>
  ];

  const paragraphs = [
    "Надежная и быстрая доставка посылок с товарами купленных в интернет-магазинах",
    "Выбирайте и получайте посылку в постамате, пункте выдаче заказов или курьер доставит прямо по вашему адресу",
    "Консультируем и помогаем купить товары в любом интернет-магазине в США и Турции."
  ];

  const images = [
    DesignIllustration2,
    DesignIllustration1,
    DesignIllustration
  ];

  return (
      <>
        <Header roundedHeaderButton={roundedHeaderButton} />
        <Container>
          <TwoColumn>
            <LeftColumn>
              {headings.map((heading, index) => (
                  <Heading key={index} animate={currentSlide === index} style={{ display: currentSlide === index ? 'block' : 'none' }}>
                    {heading}
                  </Heading>
              ))}
              {paragraphs.map((paragraph, index) => (
                  <Paragraph key={index} animate={currentSlide === index} style={{ display: currentSlide === index ? 'block' : 'none' }}>
                    {paragraph}
                  </Paragraph>
              ))}
              <Actions>
                <input
                    type="text"
                    placeholder="Ваш e-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <button onClick={handleRegister}>Зарегистрироваться</button>
              </Actions>
            </LeftColumn>
            <RightColumn>
              <IllustrationContainer>
                {images.map((image, index) => (
                    <img key={index} style={{ opacity: currentSlide === index ? 1 : 0, display: currentSlide === index ? 'block' : 'none' }} src={image} alt={`Design Illustration ${index + 1}`} />
                ))}
              </IllustrationContainer>
            </RightColumn>
          </TwoColumn>
          <DecoratorBlob1 />
        </Container>
      </>
  );
};
