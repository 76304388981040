import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import tw from "twin.macro";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import jsbarcode from "jsbarcode";
import HeaderContainer from "components/headers/WarehouseAccountingHeader";
import { ReactComponent as SearchIcon } from "images/search-icon.svg";
import Arrow from "images/icon/GreenSmallArrow.svg";
import BoxIcon from "images/icon/cardboard-box_11530648.png";
import Printer from "images/icon/Printerr.svg";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'SFUIText';
        font-weight: 300;
        font-style: normal;
    }

    body, html {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'SFUIText', sans-serif;
    }
`;

const Container = styled.div`
    ${tw`min-h-screen`}
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div`
    ${tw`flex flex-col lg:flex-row`}
    flex-grow: 1;
`;

const Sidebar = styled.div`
    ${tw`bg-white shadow-lg`}
    width: 250px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    height: 100vh;

    @media (max-width: 768px) {
        width: 200px;
    }
`;

const Content = styled.div`
    ${tw`flex-grow p-8`}
    display: flex;
    flex-direction: column;
`;

const Heading = styled.h1`
    ${tw`text-green-500 font-semibold text-xl`}
`;

const Divider = styled.div`
    ${tw`bg-gray-300`}
    height: 1px;
    width: 100%;
    margin: 20px 0;
`;

const Form = styled.div`
    ${tw`flex items-center space-x-6`}
    gap: 1rem;
`;

const InputContainer = styled.div`
    ${tw`flex flex-col relative items-center`}
    flex: 1;
`;

const Input = styled.input`
    ${tw`border-2 rounded-lg p-3 w-full text-gray-600 mt-4 pl-10`}
    border-color: #D3D3D3;
    width: 96%;
    &:hover, &:focus {
        border-color: #0ABD19;
    }
`;

const SearchIconContainer = styled.div`
    ${tw`absolute inset-y-0 left-0 flex items-center pl-3 mt-4`}
    pointer-events: none;
`;

const LabelsContainer = styled.div`
    ${tw`grid grid-cols-12 gap-0 p-4`}
`;

const DataGrid = styled.div`
    ${tw`grid grid-cols-12 gap-0 bg-white p-4 rounded-md`}
    margin-top: 0px;
`;

const DataCell = styled.div`
    ${tw`p-2`}
    border: 1px solid #d3d3d3;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 29px;
    height: 40px;
`;

const CellLabel = styled.span`
    ${tw`font-semibold text-sm text-gray-800`}
`;

const PrintIcon = styled.img`
    ${tw`w-5 h-5 cursor-pointer`}
`;

const ExpectedParcelsPage = () => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const parcelsRef = collection(db, "parcels");
            const snapshot = await getDocs(parcelsRef);

            const data = snapshot.docs.map(doc => ({
                clientId: doc.data().id || doc.id, // Ensure correct field
                trackingId: doc.data().trackingNumber || "N/A",
                date: doc.data().createdAt || "Unknown",
                weight: doc.data().weight || "0",
            }));
            setTableData(data);
        };

        fetchData();
    }, []);

    const handlePrint = (trackingId, clientId, date, weight = 0) => {
        const canvas = document.createElement("canvas");

        if (trackingId) {
            jsbarcode(canvas, trackingId, { format: "CODE128" });
        }

        const barcodeDataUrl = trackingId ? canvas.toDataURL("image/png") : '';
        const printWindow = window.open("", "_blank");

        printWindow.document.write(`
        <html>
            <head><title>Print Tracking Number</title></head>
            <body>
                <h1>EPARCEL</h1>
                <p>Date: ${date || "Unknown"}</p>
                <p>Weight: ${weight} kg</p>
                <p>Tracking:</p>
                ${trackingId ? `<img src="${barcodeDataUrl}" alt="Tracking Barcode" /><br><p>${trackingId}</p>` : '<p>N/A</p>'}
                <p>Client ID: ${clientId}</p>
            </body>
        </html>
    `);
        printWindow.document.close();
        printWindow.print();
    };


    return (
        <Container>
            <GlobalStyle />
            <HeaderContainer />
            <MainContent>
                <Sidebar>
                    {/* Sidebar Code */}
                </Sidebar>
                <Content>
                    <Heading>Ожидаемые</Heading>
                    <Divider />
                    <Form>
                        <InputContainer>
                            <SearchIconContainer>
                                <SearchIcon />
                            </SearchIconContainer>
                            <Input placeholder="Search" />
                        </InputContainer>
                    </Form>
                    <LabelsContainer>
                        <CellLabel style={{ gridColumn: 'span 3' }}>ID клиента</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 4' }}>Tracking ID</CellLabel>
                        <CellLabel style={{ gridColumn: 'span 2' }}>Print</CellLabel>
                    </LabelsContainer>
                    <DataGrid>
                        {tableData.map((row, index) => (
                            <React.Fragment key={index}>
                                <DataCell style={{ gridColumn: 'span 3' }}>{row.clientId}</DataCell>
                                <DataCell style={{ gridColumn: 'span 4' }}>{row.trackingId}</DataCell>
                                <DataCell style={{ gridColumn: 'span 2' }}>
                                    <PrintIcon
                                        src={Printer}
                                        alt="Print"
                                        onClick={() => handlePrint(row.trackingId, row.clientId, row.date, row.weight)}
                                    />
                                </DataCell>
                            </React.Fragment>
                        ))}
                    </DataGrid>
                </Content>
            </MainContent>
        </Container>
    );
};

export default ExpectedParcelsPage;
