import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MainFooterWithLinks";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "ПОЛИТИКА ЭКСПОРТНОГО И САНКЦИОННОГО КОНТРОЛЯ (далее – ПОЛИТИКА)" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h3>
              ДИСКЛЕЙМЕР
            </h3>
            <p>для соблюдения ПОЛИТИКИ мы рекомендуем Вам до заказа услуг ознакомиться со списками товаров, которые
              разрешены/запрещены к ввозу/вывозу в нужных Вам странах и списком лиц, сотрудничество с которыми может
              быть ограничено.</p>
            <h3>
              ЦЕЛИ И ЗАДАЧИ
            </h3>
            <p>
              - ПОЛИТИКА направлена на соблюдение законов международной торговли и услуг, включая соответствующие
              ограничения на экспорт и санкции; <br/> <br/>
              - Несоблюдение ПОЛИТИКИ может привести к суровым уголовным и гражданским санкциям для Исполнителя,
              Агентов, Клиента, выгодоприобретателя, а также нанести ущерб репутации;<br/> <br/>
              - прописанные в ПОЛИТИКЕ гарантии носят форму обязательства, нарушение гарантий является основанием для
              взыскания убытков, иных компенсаций и изменений условий сотрудничества; <br/> <br/>
              - так как законодательство экспортного и санкционного контроля стран изменчиво, то ПОЛИТИКА может быть
              изменена в любое время без дополнительного уведомления. Просим Вас внимательно отслеживать такие
              изменения; <br/> <br/>
              - «Мы», «Нас», «Нам» в ПОЛИТИКЕ обозначает лицо, оказывающее услугу (или его контрагентов) и
              ссылающееся/разместившее на ПОЛИТИКУ в своих документах, договорах, условиях оказания услуг, сайтах и
              т.д.; <br/> <br/>
              - знак «/» в ПОЛИТИКЕ используется в значении «и (или)»; <br/> <br/>
              - Мы можем проводить дополнительную проверку отправителей, получателей, конечных получателей (end users),
              иных лиц, запросить дополнительный гарантии/подтверждения или отказать в услуге, если у Нас есть разумные
              основания предполагать, что оказание услуги может нарушить цели ПОЛИТИКИ. <br/> <br/>
            </p>
            <h3>УСЛОВИЯ ПОЛИТИКИ</h3>
            <p>Пользуясь сайтом/мобильным приложением, на котором Вы знакомитесь с ПОЛИТИКОЙ и/или заказывая/получая
              через настоящий сайт/мобильное приложение услуги и/или в случае, если условия оказания приобретаемых Вами
              услуг содержат ссылку на ПОЛИТИКУ, Вы принимаете и соглашаетесь со следующими условиями: <br/><br/>
              1) Вы гарантируете, что Вы не включены в какие-либо блокирующие санкционные списки США (SDN list),
              Великобритании, Европейского союза или любого государства-члена Европейского союза (далее – санкционные
              списки), страны-отправления или страны получения отправления. <br/><br/>
              2) Вы гарантируете, что Вы не включены в какие-либо блокирующие санкционные списки США (SDN list),
              Великобритании, Европейского союза или любого государства-члена Европейского союза (далее – санкционные
              списки) или страны, в которой должна быть оказана услуга. <br/><br/>
              3) Если Вы являетесь юридическим лицом, Вы гарантируете, что Вы, Ваши собственники, контролирующие лица и
              должностные лица не включены в блокирующие санкционные списки США, Великобритании, Европейского союза или
              любого государства-члена Европейского союза (далее – санкционные списки), страны-отправления или страны
              получения отправления. <br/><br/>
              4) Если Вы являетесь юридическим лицом, Вы гарантируете, что Вы, Ваши собственники/ контролирующие лица/
              должностные лица/ конечные бенефициары не включены в блокирующие санкционные списки США, Великобритании,
              Европейского союза или любого государства-члена Европейского союза (далее – санкционные списки) или
              страны, в которой должна быть оказана услуга. <br/><br/>
              5) Вы гарантируете, что получаемые Вами услуги не будут прямо или косвенно использоваться в целях,
              связанных с деятельностью/организацией деятельности, направленной на прямое или косвенное нарушение
              секторальных или блокирующих санкций США, Великобритании, Европейского союза или любого государства-члена
              Европейского союза, страны-отправления, страны получения отправления или страны оказания услуг, в том
              числе в пользу лиц, включенных в санкционные списки. <br/><br/>
              6) Вы гарантируете, что до момента передачи Нам тщательно проверили содержимое отправления/состав услуг и
              доставка такого отправления в страну назначения/оказание услуги являются законными с точки зрения
              применимого к участникам правоотношений законодательства. <br/><br/>
              7) Вы гарантируете, что до момента передачи Нам тщательно проверили содержимое отправления/состав услуг и
              содержимое отправления не попадает ни в какие списки экспортного, санкционного или иного вида контроля и
              может на законных основаниях выбывать из страны отправления в страну конечного назначения. <br/><br/>
              8) Вы гарантируете, что в отношении вложений в отправления/заказанных услуг выполнены все обязательные
              требования таможенного, санкционного законодательства, законодательства об экспортном контроле и контроле
              за перемещением товаров военного и двойного назначения и другие аналогичные обязательные требования
              национального и международного права, получены обязательные разрешения и лицензии и Вы можете подтвердить
              документально выполнение перечисленных требований. <br/><br/>
              9) Вы гарантируете, что п. 1 – 8 ПОЛИТИКИ соблюдены и фактическим выгодоприобретателем услуг/отправителем
              и получателем отправлений (услуг)/для конечных пользователей перемещаемых в отправлениях вложениях (end
              users). <br/><br/>
              10) Вы гарантируете, что самостоятельно несете ответственность за отслеживание изменений ПОЛИТИКИ.</p>
            <br/> <br/>
            11) В случае выявления нарушений/обстоятельств, свидетельствующих о наличии нарушений/признаков нарушений
            данных в ПОЛИТИКЕ гарантий мы вправе одновременно или по отдельности совершать следующие действия: <br/>
            <br/>
            <ul>
              <li>запросить дополнительную информацию и разъяснения;</li>
              <li>приостановить оказание услуг;</li>
              <li>расторгнуть все или часть заключенных с Вами или в Ваших интересах договоры;</li>
              <li>потребовать материальное возмещение причиненных убытков и стоимости услуг всех привлеченных
                Нами/Нашими партнерами специалистов для решения возникших из-за нарушения Вами Политики ситуаций.
              </li>
            </ul>
            <br/>
            12) Заказывая услугу Вы подтверждаете намерение компенсировать Наши убытки (в том числе Наших должностных
            лиц, агентов, посредников и других Наших Партнеров), возникшие из-за несоблюдения Вами ПОЛИТИКИ <br/> <br/>
            13) Расторжение Нами любых заключённых с Вами договоров/ отказ от оказания Вам услуг из-за несоответствия
            услуг условиям ПОЛИТИКИ/невозможность оказания Вам услуг (даже если это выявилось после начала оказания
            Услуг) из-за несоблюдения Вами ПОЛИТИКИ не является расторжением договора/отказом от оказания
            услуги/нарушением условий оказания услуг/нарушением условий договора по Нашей вине и не влечет применение к
            Нам любых мер ответственности, связанных с расторжением договора или неисполнением Нами обязательств
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
